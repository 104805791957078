import { isArray, isEmpty } from 'lodash-es';
// import { klona } from 'klona';
import { requestData } from 'API/data';
import answersGlobalAPI from 'API/answersGlobal';
import { getBaseUrl } from 'Utils/api';
import { superagentFilter } from 'Utils/apiHelpers';
import AutoBulkFetcher from 'Utils/autoBulkFetcher';
import eventBus from 'Utils/eventBus';
import { processStats } from 'Utils/stat';
import { handleAuthError } from 'Utils/authHelpers';
import {
  getPrefixedFilterKeyIn,
  getFilterOperator,
} from 'Utils/filterBox';
import { store } from '@/store';

// goes outside segment customer/segment/proxy is defined in filter
function superstats(question, filter = null) {
  return getBaseUrl('/shinyform/api/answers/stats/')
    .query({ question })
    .use(superagentFilter(filter))
    .catch(handleAuthError)
    .then((response) => response.body, () => [])
    .then((results) => {
      if (results[0]) return results[0];
      throw new Error(`Item(${JSON.stringify(filter)}) not found.`);
    })
    .catch((error) => ({ error }))
    .then(processStats(question, filter));
  // TODO: add compare against global only
}

// ? BENCHMARKS, Set global and customer stats default to false?
function stats(segment, question, filter = null, globalStats = false, customerStats = false) {
  // TODO: needs cleanup, AutoBulkFetcher is useless here, it is always a single question. Do like get below?
  const statsBf = new AutoBulkFetcher('/shinyform/api/answers/stats/', 'question__in');
  const p = statsBf.add(question, segment, filter)
    .catch((error) => ({ error }))
    .then(processStats(question, filter))
    .then((stat) => {
      const promises = [];
      if (globalStats) {
        promises.push(answersGlobalAPI.stats(question, filter)
          .then((gstats) => {
            stat.global = gstats;
          }));
      }
      if (customerStats) {
        const customer = store.getters.customerId;
        promises.push(answersGlobalAPI.stats(question, { ...filter, customer })
          .then((gstats) => {
            stat.customer = gstats;
          }));
      }
      return Promise.all(promises).then(() => stat);
    });
  statsBf.fetch();
  return p;
}

// ? BE Doesn’t have support for CNPS answers as 0-10 yet, it returns 'detractor' etc.
async function get({ segmentId = null, questionIds = [] } = {}) {
  const queries = [{
    filter: {
      question: {
        question_id: questionIds.map((q) => Number(q)),
      },
      ...(segmentId && {
        applicant: {
          segment: {
            segment_id: segmentId,
          },
        },
      }),
      // TODO: ANSWERS, add filter for date, tags, customerProxies etc. probably passed down as argument
    },
    groupBy: [
      'question',
      'score',
    ],
    hierarchy: 2,
  }];
  return requestData(queries);
}
function transformFreeTextQuery(query) {
  const { segment, translate, question, customerProxies } = query;
  const topicFilterKey = query['!topics'] ? '!topics' : 'topics';
  const topics = query[topicFilterKey]?.join(', ');

  const topicInKey = getPrefixedFilterKeyIn(
    'topic',
    getFilterOperator(topicFilterKey),
  );

  const segmentFilter = isArray(segment) ? { segment__in: segment.join(',') } : { segment__in: segment };
  const customerProxyFilter = { customer_proxy__in: customerProxies.join(',') };
  return {
    ...(segment && segmentFilter),
    ...(!isEmpty(customerProxies) && customerProxyFilter),
    ...(translate && { translate }),
    ...(topics && { [topicInKey]: topics }),
    question,
  };
}

function text({ query, filter = null, abortToken = null }) {
  const request = getBaseUrl('/shinyform/api/answers/text/')
    .query(transformFreeTextQuery(query))
    .use(superagentFilter(filter))
    .then((response) => response.body, handleAuthError);

  if (abortToken) {
    eventBus.$once(`abortRequest:${abortToken}`, () => {
      if (request.xhr && request.xhr.status === 0) request.abort();
    });
  }

  return request;
}

export default {
  superstats,
  stats,
  get,
  text,
};
