<template>
  <div class="new-proxy-filter">
    <transition name="fade-in">
      <div
        v-if="adding"
        class="form-group form-group-highlight tc-pane-bleed mb-0"
      >
        <tabs-navigation
          v-model="activeTab"
          class="mb-2 tabs-navigation-sm relative"
          :tabs="tabs"
        />
        <label class="flex-apart">
          <span>{{ $pgettext('Label — Choose proxy', 'Proxy') }}</span>
          <i
            class="zmdi zmdi-close subtle-text cursor-pointer mr-0.5"
            :title="$gettext('Stäng')"
            @click.prevent="adding = false"
          />
        </label>
        <tc-select
          ref="tcSelect"
          v-model="proxies"
          :multiselect="true"
          :on-change="customerProxiesOptions"
          :placeholder="$gettext('Välj proxy')"
          :no-options-text="$gettext('Inga fler proxies finns')"
          @update:model-value="update"
        />
        <button
          :disabled="!hasProxy || proxies.length < 1"
          class="btn btn-link small-text ml-1 mr-2 mt-2"
          type="button"
          @click.prevent="addProxy"
        >
          <i class="zmdi zmdi-plus mr-0.5" />
          <span>{{ $pgettext('Button — New proxy filter add','Lägg till') }}</span>
        </button>
      </div>
    </transition>
    <button
      v-if="!adding"
      class="btn btn-link small-text mr-2"
      type="button"
      @click.prevent="adding = !adding"
    >
      <i class="zmdi zmdi-plus mr-0.5" />
      <span>{{ $pgettext('Button — NewProxyFilter', 'Lägg till avgränsning på proxy') }}</span>
    </button>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { FILTER_OPERATOR_EXCLUDE, FILTER_OPERATOR_INCLUDE } from 'Utils/filterBox';
import TabsNavigation from 'Components/parts/widgets/TabsNavigation';

export default {
  components: {
    TabsNavigation,
  },
  props: {
    filter: {
      type: Array,
      default: () => [],
    },
  },
  emits: ['add-filter', 'update:filter'],
  data() {
    return {
      proxies: [],
      adding: false,
      activeTab: FILTER_OPERATOR_INCLUDE,
      tabs: [
        {
          value: FILTER_OPERATOR_INCLUDE,
          title: this.$gettext('Inkludera'),
        },
        {
          value: FILTER_OPERATOR_EXCLUDE,
          title: this.$gettext('Exkludera'),
        },
      ],
    };
  },
  computed: {
    ...mapGetters([
      'fetchingProxies',
      'customerProxies',
      'customerProxiesOptions',
    ]),
    hasProxy() {
      return this.proxies[0] !== undefined && this.proxies[0].value;
    },
  },
  watch: {
    filter: {
      deep: true,
      handler(newFilter) {
        if (newFilter.length) this.setup(newFilter);
      },
    },
  },
  mounted() {
    this.$nextTick(() => {
      this.$nextTick(() => {
        if (this.customerProxies) this.setup(this.filter);
      });
    });
  },
  methods: {
    addProxy() {
      this.$emit('add-filter', { proxies: this.proxies.map((proxyObject) => proxyObject.value), operator: this.activeTab });
      this.adding = false;
      this.clear();
    },
    update(proxies) {
      this.$emit('update:filter', proxies.map((proxyObject) => proxyObject.value));
    },
    clear() {
      this.proxies = [];
      this.$refs.tcSelect.clear();
    },
    setup(filter) {
      const filterIds = filter.map((entry) => {
        if (entry.value) return entry.value;
        return entry;
      });

      this.proxies = this.customerProxies
        .filter((proxy) => filterIds.includes(proxy.id))
        .map((proxy) => ({
          value: proxy.id,
          label: proxy.internal_name || proxy.name,
        }));
    },
  },
};
</script>
