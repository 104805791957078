<template>
  <div class="tc-sw-ranking">
    <CardDiv
      v-tc-loader-bar="loading"
      :loading="loading || null"
    >
      <template #body>
        <div class="p-card sw-wrapper">
          <div
            v-if="scoresFiltered.length !== 0"
            class="tc-table-wrapper"
          >
            <performance-score-table
              :card="card"
              :comparable-option="comparableOption"
              :items="filteredScoresWithResponseRate"
              :is-print-view-route="isPrintViewRoute"
              usecase="swot"
              @update-drag-area="updateLayoutThrottled"
            />
          </div>
          <div
            v-else
            data-testid="tableHidden"
            class="tc-card-message-wrapper"
          >
            <div
              v-if="!loading"
              class="tc-card-message-overlay tc-card-message-overlay-tight"
            >
              <p v-if="useComment">
                {{ $gettext('Vald data och benchmark är för lika för att kunna jämföras.') }}
              </p>
              <template v-else>
                <p class="mb-1">
                  {{ $gettext('Kunde inte sammanställa styrkor och svagheter med nuvarande filter.') }}
                </p> <p>
                  {{ $gettext('Prova att byta filter.') }}
                </p>
              </template>
            </div>
            <div class="tc-card-message-underlay">
              <performance-score-table
                :card="card"
                :comparable-option="comparableOption"
                :items="placeholderScores"
                :skeleton-loader="true"
                usecase="swot"
              />
            </div>
          </div>
        </div>
        <template v-if="!isPrintViewRoute">
          <div
            class="tc-sw-help-btn px-2"
            :class="{'py-2': !showHelp, 'pt-2': showHelp}"
          >
            <p
              v-if="hiddenQuestionsAmount > 0 && card?.metadata?.show?.onlyBenchmarkableQuestions === true"
              v-tooltip="$pgettext(
                'Tooltip — Hidden custom questions',
                'Ändra visningsinställningarna för att visa alla frågor.'
              )"
              class="subtle-text small-text px-1 py-1 mb-0 text-left"
            >
              {{ $npgettext(
                'Helptext — Hidden custom questions',
                '%{amount} fråga med intern benchmark göms just nu',
                '%{amount} frågor med intern benchmark göms just nu',
                hiddenQuestionsAmount,
                { amount: hiddenQuestionsAmount }
              ) }}
            </p>
            <button
              class="help-button btn btn-link px-1"
              @click="showHelp = !showHelp"
            >
              {{ $gettext('Hjälp att förstå') }} <i class="zmdi zmdi-help" />
            </button>
          </div>
          <div
            v-if="showHelp"
            class="tc-sw-help p-card"
          >
            <div class="row">
              <div class="col-xs-12 col-xxl-10">
                <small class="subtle-text small-header">
                  {{ $gettext('Prioritetsskala:') }}
                </small>
                <div class="row">
                  <ol class="plain-list small-text col-xs-12 col-md-6">
                    <li class="pb-1">
                      <PerformanceScoreIndicator
                        class="tc-sw-indicator-wrapper mr-1"
                        :score="95"
                        :comparable-option="comparableOption"
                        :show-compare="true"
                        usecase="swot"
                      />
                      <span>
                        {{ $gettext('Bra jobbat, bland de bästa') }}
                      </span>
                    </li>
                    <li class="pb-1">
                      <PerformanceScoreIndicator
                        class="tc-sw-indicator-wrapper mr-1"
                        :score="70"
                        :comparable-option="comparableOption"
                        :show-compare="true"
                        usecase="swot"
                      />
                      <span>
                        {{ $gettext('Snart bland de bästa') }}
                      </span>
                    </li>
                    <li class="pb-1">
                      <PerformanceScoreIndicator
                        class="tc-sw-indicator-wrapper mr-1"
                        :score="54"
                        :comparable-option="comparableOption"
                        :show-compare="true"
                        usecase="swot"
                      />
                      <span>
                        {{ $gettext('Nära snittet') }}
                      </span>
                    </li>
                  </ol>
                  <ol class="plain-list small-text col-xs-12 col-md-6">
                    <li class="pb-1">
                      <PerformanceScoreIndicator
                        class="tc-sw-indicator-wrapper mr-1"
                        :score="45"
                        :comparable-option="comparableOption"
                        :show-compare="true"
                        usecase="swot"
                      />
                      <span>
                        {{ $gettext('Nära snittet') }}
                      </span>
                    </li>
                    <li class="pb-1">
                      <PerformanceScoreIndicator
                        class="tc-sw-indicator-wrapper mr-1"
                        :score="33"
                        :comparable-option="comparableOption"
                        :show-compare="true"
                        usecase="swot"
                      />
                      <span>
                        {{ $gettext('Håll koll på denna fråga') }}
                      </span>
                    </li>
                    <li class="pb-1">
                      <PerformanceScoreIndicator
                        class="tc-sw-indicator-wrapper mr-1"
                        :score="9"
                        :comparable-option="comparableOption"
                        :show-compare="true"
                        usecase="swot"
                      />
                      <span>{{ $gettext('Detta bör åtgärdas') }}
                      </span>
                    </li>
                  </ol>
                </div>
              </div>
              <div class="col-lg-9 col-xxl-8 col-xxxl-7">
                <p class="help-block">
                  <!-- eslint-disable max-len -->
                  {{ $gettext(
                    'Är stapeln röd har du ett problem som behöver åtgärdas eller ses över. Om den istället är grön presterar du lite bättre eller mycket bättre än snittet. En tom stapel eller gul färg betyder att du är ungefär lika bra som snittet.'
                  ) }}
                  <br>
                  {{
                    $gettext('Frågor som inte kan besvaras med ja/nej eller gradering samt frågor med få svar är exkluderade.')
                  }}
                  <br>
                  {{
                    $gettext('Om du ser en stapel markerad som "Endast intern benchmark" så betyder det att det inte finns någon extern benchmark att jämföra mot. Den kan gömmas då den inte är relevant.')
                  }}
                <!-- eslint-enable max-len -->
                </p>
              </div>
            </div>
          </div>
        </template>
      </template>
    </CardDiv>
  </div>
</template>

<script>
import { klona } from 'klona';
import { isArray, isEmpty, debounce, throttle, isEqual } from 'lodash-es';
import { mapGetters } from 'vuex';
import { formatFiltersForRequest, requestData } from 'API/data';
import { requestSWOT } from 'API/analysis';
import { equalMetadata } from 'Utils/card';
import { equalLiteral } from 'Utils/general';
import { KEY_METRIC_GRAPH_TYPES } from 'Utils/graph';
import eventBus from 'Utils/eventBus';
import CardDiv from 'Components/parts/CardDiv';
import PerformanceScoreTable from 'Components/parts/graph/PerformanceScoreTable';
import PerformanceScoreIndicator from 'Components/parts/graph/PerformanceScoreIndicator';

export default {
  name: 'SWRanking',
  components: {
    CardDiv,
    PerformanceScoreTable,
    PerformanceScoreIndicator,
  },
  props: {
    board: Object,
    card: Object,
    compiledFilter: Object,
    compiledBenchmark: Object,
    contextBenchmark: Object,
    isInBoard: {
      type: Boolean,
      default: false,
    },
    previewMode: {
      type: Boolean,
      default: false,
    },
    hydrateProps: { // ? Fill in comp from outside
      type: [Object, null],
      default: null,
    },
  },
  emits: ['loading', 'open-edit-modal', 'update-drag-area', 'open', 'close', 'hydrate-props'],
  data() {
    return {
      showHelp: false,
      abortToken: Math.random().toString(10).substring(2),
      loading: false,
      swotResponse: [],
      responseRateData: [],
      placeholderScores: [{
        step: 'step5',
        title: 'How likely would recommend us?',
        score: 99,
        benchmark: true,
        questionId: 502,
      }, {
        step: 'step5',
        title: 'Would you apply for a job here again?',
        score: 84,
        benchmark: true,
        questionId: 512,
      }, {
        step: 'step2',
        title: 'My interview was scheduled in a reasonable time frame',
        score: 62,
        benchmark: true,
        questionId: 398,
      }, {
        step: 'step2',
        title: 'How likely would recommend us?',
        score: 45,
        benchmark: true,
        questionId: 405,
      }, {
        step: 'step5',
        title: 'Did you understand the steps?',
        score: 28,
        benchmark: true,
        questionId: 425,
      }, {
        step: 'step1',
        title: 'Did you experience any technical issues?',
        score: 0,
        benchmark: true,
        questionId: 224,
      }],
      hasSetup: false,
      getSWOT: () => {},
      updateLayoutThrottled: () => {},
    };
  },
  computed: {
    ...mapGetters([
      'me',
      'segment',
      'segmentId',
      'segmentFilter',
      'customerAllSteps',
      'modalCard',
      'customerId',
      'customerName',
    ]),
    isPrintViewRoute() { return this.$route.name?.indexOf('print-') > -1; },
    localCard() { return this.previewMode ? this.modalCard : this.card; },
    hiddenQuestionsAmount() {
      const stepKey = Object.keys(this.compiledFilter || {}).find((key) => ['step', '!step'].includes(key));

      return this.swotResponse.length === 0
        ? 0
        : this.swotResponse
          .filter(({ step }) => {
            if (!stepKey) return true;
            return stepKey === 'step' ? this.compiledFilter?.step?.includes(step) : !this.compiledFilter['!step'].includes(step);
          })
          .reduce((acc, item) => {
            if (item.is_benchmark_question && item.is_custom_question) return acc + 1;
            return acc;
          }, 0);
    },
    scores() {
      if (this.swotResponse.length === 0) return [];
      return this.swotResponse
        .filter((item) => (
          item.is_benchmark_question
          && ( // ? Filter out custom questions if onlyBenchmarkableQuestions is true, keep if `this.compiledBenchmark.general.includes('company')`
            (this.card?.metadata?.show?.onlyBenchmarkableQuestions === true && item?.is_custom_question !== true)
         || (this.card?.metadata?.show?.onlyBenchmarkableQuestions !== true)
         || (this.compiledBenchmark?.general?.includes('company') && item?.is_custom_question === true)
          )
        ))
        .map((item) => ({
          step: item.step,
          title: item.question_translation,
          score: ((item.priority ?? 50) - 100) * -1, // convert 45 → 55 etc
          benchmark: item.is_benchmark_question,
          isOnlyInternalBenchmark: (!this.compiledBenchmark?.general?.includes('company') && item?.is_custom_question) ?? false,
          questionId: item.question_id,
        }))
        .sort((a, b) => b.score - a.score);
    },
    scoresFiltered() {
      const stepKey = Object.keys(this.compiledFilter || {}).find((key) => ['step', '!step'].includes(key));
      if (!stepKey) return this.scores;
      return this.scores.filter(({ step }) => (
        stepKey === 'step' ? this.compiledFilter?.step?.includes(step) : !this.compiledFilter['!step'].includes(step)
      ));
    },
    filteredScoresWithResponseRate() {
      return this.scoresFiltered.map((score) => {
        const respondent_count = this.responseRateData[score.questionId]?.respondent_count || null;
        return { ...score, respondent_count };
      });
    },
    currentFilter() {
      const { tags, answers, segment, date, customer_proxy } = formatFiltersForRequest(this.compiledFilter);
      const applicant = { ...(tags && { tags }), ...(answers && { answers }) };
      return {
        ...(!isEmpty(applicant) && { applicant }),
        ...(!isEmpty(segment) ? { segment } : { segment: { segment_id: this.segmentId } }),
        ...(date && { date }),
        ...(customer_proxy && { customer_proxy }),
      };
    },
    compareBy() {
      if (this.compiledBenchmark?.general?.includes('company')) return { customer_proxy: { customer_id: this.customerId } };
      if (this.hasBenchmarkFilter) return { customer_proxy: this.benchmarkFilter };
      return {};
    },
    comparableOption() {
      if (this.compiledBenchmark?.general?.includes('company')) return [{ label: this.customerName, value: this.customerName, icon: 'zmdi-city zmdi-hc-fw' }];
      return [{ value: 'global', label: this.hasBenchmarkFilter ? this.$pgettext('Sector', 'Benchmark') : this.$pgettext('Sector', 'Global'), icon: 'zmdi-globe' }];
    },
    compareToFilter() {
      return {
        ...this.compareBy,
        date: this.compiledFilter?.date || this.segmentFilter?.date || {},
      };
    },
    hasBenchmarkFilter() {
      if (isEmpty(this.compiledBenchmark) || this.compiledBenchmark?.general?.includes('global')) return false;
      return Object.values(this.benchmarkFilter)
        .reduce((acc, bench) => (bench?.length >= 0 ? acc + bench.length : acc), 0) > 0;
    },
    benchmarkFilter() {
      /* eslint-disable max-len */
      const benchmarkSize = this.modalCard?.metadata?.benchmark?.size || this.card?.metadata?.benchmark?.size || [];
      const benchmarkSector = this.modalCard?.metadata?.benchmark?.sector || this.card?.metadata?.benchmark?.sector || [];
      const benchmarkLocation = this.modalCard?.metadata?.benchmark?.location || this.card?.metadata?.benchmark?.location || [];
      const benchmarkIndustry = this.modalCard?.metadata?.benchmark?.industry || this.card?.metadata?.benchmark?.industry || [];

      return {
        ...(!isEmpty(benchmarkSize) && { size: benchmarkSize }
          || !isEmpty(this.contextBenchmark?.size) && { size: this.contextBenchmark.size }),
        ...(!isEmpty(benchmarkSector) && { sector: benchmarkSector }
          || !isEmpty(this.contextBenchmark?.sector) && { sector: this.contextBenchmark.sector }),
        ...(!isEmpty(benchmarkLocation) && { country: benchmarkLocation }
          || !isEmpty(this.contextBenchmark?.location) && { country: this.contextBenchmark.location }),
        ...(!isEmpty(benchmarkIndustry) && { industry: benchmarkIndustry }
          || !isEmpty(this.contextBenchmark?.industry) && { industry: this.contextBenchmark.industry }),
      }; /* eslint-enable max-len */
    },
    sectorOptions() {
      if (this.compiledBenchmark?.general?.includes('company')) return [{ label: this.customerName, value: this.customerName, icon: 'zmdi-city zmdi-hc-fw' }];
      return [{ value: 'global', label: this.hasBenchmarkFilter ? this.$pgettext('Sector', 'Benchmark') : this.$pgettext('Sector', 'Global'), icon: 'zmdi-globe' }];
    },
    useComment() {
      return this.swotResponse.length !== 0
        && this.swotResponse.every((row) => row.comment === 'Selected data and benchmark data are too similar in size to be compared');
    },
    watchedTrigger() {
      return this.hasSetup ? klona({
        segmentId: this.segmentId,
        cardMetadata: this.card?.metadata,
        compiledFilter: this.compiledFilter,
        compiledBenchmark: this.compiledBenchmark,
        benchmarkFilter: this.hasBenchmarkFilter && this.benchmarkFilter || {},
        comparableOption: this.comparableOption,
      }) : {
        segmentId: null,
        cardMetadata: {},
        compiledFilter: {},
        benchmarkFilter: {},
        compiledBenchmark: {},
        comparableOption: null,
      };
    },
  },
  watch: {
    loading(newVal) { if (!newVal && this.isInBoard) { this.$emit('loading', newVal); this.updateLayoutThrottled(); } }, // ? Triggers fullyLoadedCards so Sejda knows when to print
    watchedTrigger(newVal, oldVal) {
      let request = false;

      if (this.isInBoard) {
        if (!equalMetadata(newVal.cardMetadata, oldVal.cardMetadata, ['level'])) { request = true; }
      }
      if (!equalLiteral(newVal.compiledFilter, oldVal.compiledFilter)) { request = true; }
      if (!equalLiteral(newVal.benchmarkFilter, oldVal.benchmarkFilter)) { request = true; }
      if (newVal.comparableOption?.[0]?.value !== oldVal.comparableOption?.[0]?.value) { request = true; }
      if (newVal.segmentId !== oldVal.segmentId) {
        request = true;
      }
      if (request) this.getSWOT();
      this.$nextTick(() => {
        if (this.isInBoard) this.updateLayoutThrottled();
      });
    },
    async swotResponse() {
      const query = {
        filter: {
          ...this.currentFilter,
          question: {
            question_id: this.swotResponse.map((q) => q.question_id),
          },
        },
        groupBy: ['question'],
        hierarchy: 1,
      };
      const res = await requestData([query]);
      this.responseRateData = res[0] || {};
    },
  },
  beforeUnmount() {
    eventBus.$emit(`abortRequest:${this.abortToken}`);
    if (typeof this.getSWOT?.cancel === 'function') this.getSWOT.cancel(); // ? Cancel all pending debounced requests
  },
  created() {
    this.updateLayoutThrottled = throttle(() => this.updateLayout, 10, { trailing: true, leading: false });
    // ? Debounced fn needs to be in data and not in methods to support more instances of this component
    this.getSWOT = debounce(async (filter = this.currentFilter, compareToFilter = this.compareToFilter) => {
      this.loading = true;
      let response;
      const query = { filter, compareToFilter };
      const useSavedResponse = isEqual(this.hydrateProps?.[KEY_METRIC_GRAPH_TYPES.SWRanking]?.query, query)
        && !isEmpty(this.hydrateProps?.[KEY_METRIC_GRAPH_TYPES.SWRanking]?.swotResponse);

      try {
        if (useSavedResponse) { // eslint-disable-line max-len
          response = this.hydrateProps[KEY_METRIC_GRAPH_TYPES.SWRanking].swotResponse;
        } else {
          response = await requestSWOT(query, this.abortToken);
        }
      } catch (err) {
        console.error('[TC] Error while requesting SWOT', err); // eslint-disable-line no-console
      } finally {
        this.loading = false;
        this.swotResponse = isArray(response) ? response : [];
        if (this.isInBoard) {
          this.$emit('hydrate-props', { [KEY_METRIC_GRAPH_TYPES.SWRanking]: {
            swotResponse: this.swotResponse,
            query,
          } });
          this.updateLayoutThrottled();
        }
      }
    }, 200);
  },
  mounted() {
    if (this.isPrintViewRoute) this.showHelp = true;
    this.hasSetup = true;
  },
  methods: {
    updateLayout() { this.$emit('update-drag-area'); },
    reformatAsOption(item, arr) {
      if (item === null || item === undefined || item.length === 0) return null;
      if (Array.isArray(item)) return item;
      return arr.filter((arrItem) => arrItem.value === item);
    },
  },
};
</script>
