<template>
  <DropdownSelect
    :groups="groups"
    :dropdown-settings="dropdownSettings"
    :operator="badgeItem.operator"
    @selected="emit('selected', $event)"
    @update:search-term="searchTerm = $event"
    @load-more="loadMore"
    @update:operator="updateOperator"
  />
</template>

<script setup>
import { ref, computed, watch } from 'vue';
import { computedAsync } from '@vueuse/core';
import { isEmpty, debounce } from 'lodash-es';
import { isTopicable } from 'API/topics';
import { questionOption } from 'Utils/question';
import { markLabel } from 'Utils/tcselectHelpers';
import Paginator from 'Utils/Paginator';
import RequestPaginator from 'Utils/fresh-paginators/RequestPaginator';
import SearchPopulator from 'Utils/fresh-paginators/SearchPopulator';
import { translateTerm, translateBaseValue, translateTermOption } from 'Utils/general';
import {
  KEY_METRIC_ALLOWLIST_LEVELS,
  KEY_METRIC_GRAPH_TYPES,
  KEY_METRIC_ALLOWLIST_BADGES,
  QUESTION_ALLOWLIST_BADGES,
  allFilters,
  GOAL_GRAPH_TYPES,
  GRAPH_TYPES,
} from 'Utils/graph';
import DropdownSelect from 'Components/parts/filter-dropdowns/DropdownSelect';
import { store } from '@/store';
import gettext from '@/gettext';

const { $gettext, $pgettext } = gettext;

const props = defineProps({
  includeGroup: {
    type: String,
    default: null,
  },
  excludeGroups: {
    type: Array,
    default: () => ([]),
  },
  badgeItem: {
    type: Object,
    default: () => ({}),
  },
  dropdownSettings: {
    type: Object,
    default: () => ({}),
  },
});
const emit = defineEmits(['selected', 'update:operator']);

// Options for the filter
// segment -> values
// proxies -> values
// text topics -> values
// tags -> keys -> values
// answers -> keys -> values

const searchTerm = ref('');
const searchFn = (opt) => {
  if (searchTerm.value === '') return true;
  const optLowerCase = opt?.label?.toLowerCase() ?? opt?.value?.toLowerCase() ?? '';
  return optLowerCase.indexOf(searchTerm.value.toLowerCase()) > -1;
};

// Local Search
const segmentOptions = ref(store.getters.segmentOptions(searchTerm.value) || []);
const topicOptions = ref(store.getters.topicOptions(searchTerm.value) || []);
const isKeyMetricCard = computed(() => store.getters.modalCard?.metadata?.graphType !== 'undefined'
        && Object.values(KEY_METRIC_GRAPH_TYPES).includes(store.getters.modalCard?.metadata?.graphType?.selected));
const isGoal = computed(() => GOAL_GRAPH_TYPES[store.getters.modalCard?.metadata?.graphType?.selected]);
const stepOptions = computed(() => {
  let options = [];
  if (isKeyMetricCard.value) {
    const { any_step, specific } = KEY_METRIC_ALLOWLIST_LEVELS[store.getters.modalCard.metadata.graphType.selected];
    const customerSteps = store.getters.customerAllStepsOptions.map((option) => {
      if (option?.inactive) return { ...option, sidelabel: $pgettext('Sidelabel — Inactive step', 'Pausat formulär') };
      return option;
    }) || [];
    if (any_step) options = [...options, ...customerSteps];
    else if (specific) options = [...options, ...specific.map(translateTermOption)];
  }
  return options;
});

const hideOperatorToggle = computed(() => {
  if (props.badgeItem?.slug === 'segment') return { operatorChangeable: false };

  if (isKeyMetricCard.value && props.badgeItem?.slug === 'step') {
    // if step is required and if current badge is step, then operator should not be changeable
    const selectedGraphType = store.getters.modalCard.metadata.graphType.selected;
    const { stepRequired } = KEY_METRIC_ALLOWLIST_LEVELS[selectedGraphType];

    return { operatorChangeable: !stepRequired };
  }

  return {};
});
const dropdownSettings = computed(() => ({ ...props.dropdownSettings, ...(hideOperatorToggle.value) }));

const handleLocalSearch = (term) => {
  if (store.getters.fetchingProxies === false) store.getters.customerProxiesOptions(term).then((resp) => { customerProxiesOptions.value = resp || []; }); // eslint-disable-line
  segmentOptions.value = store.getters.segmentOptions(term);
  topicOptions.value = store.getters.topicOptions(term);
  // 1. fix a search for custoemrSteps locally here
  // customerSteps.value =
  // answer values should work here
};
watch(searchTerm, (newVal) => {
  handleLocalSearch(newVal);
}, { immediate: true });

// ? PROXIES
const customerProxiesOptions = ref([]);
const isLoadingProxies = computed(() => store.getters.fetchingProxies);
watch(isLoadingProxies, (newVal) => {
  if (newVal === false) store.getters.customerProxiesOptions(searchTerm.value).then((resp) => { customerProxiesOptions.value = resp || []; }); // eslint-disable-line
}, { immediate: true });

// ? ANSWERS (QUESTIONS → ANSWERS)
const isLoadingQuestions = computedAsync(async () => {
  if (isEmpty(store.state.questions.questions)) return true;// ? May result in an always loading state
  await store.getters.fetchingAllQuestions;
  return false;
}, true);

const questionOptionsPaginator = ref(new Paginator(store.dispatch('getAllQuestions')));

const answerValuesOptionsFn = (answerKey, questionObj = {}) => {
  const emptyOpts = [{
    boxRowType: 'answers',
    groupSlug: answerKey,
    groupTitle: '',
    loading: isLoadingQuestions.value || questionOptionsPaginator.value?.promise === undefined,
    options: [],
  }];

  let baseValues = questionObj?.options?.base_values ?? [];
  if (!baseValues.length) {
    if (questionObj?.question_type === 'yesno') baseValues = ['ja', 'nej']; // ? Should be translated
    else if (questionObj?.question_type === 'rating') baseValues = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9', '10'];
    else return emptyOpts;
  }
  const options = baseValues.map((ans) => ({
    value: ans,
    label: translateBaseValue(ans, questionObj) || ans,
    icon: 'zmdi-format-list-numbered',
    selected: (props.badgeItem?.boxRowKey === answerKey && props.badgeItem?.boxRowValue?.includes(ans)),
  })) || [];

  return [{
    boxRowType: 'answers',
    groupSlug: answerKey,
    groupTitle: '',
    loading: isLoadingQuestions.value || questionOptionsPaginator.value?.promise === undefined || false,
    options: options.filter(searchFn).map(markLabel(searchTerm.value)) || [],
  }];
};

const questionObjects = ref([]);
const questionOptions = computedAsync(async () => {
  if (isLoadingQuestions.value || questionOptionsPaginator.value?.promise === undefined) return [];
  try {
    await questionOptionsPaginator.value.promise;
    questionObjects.value = questionOptionsPaginator.value?.response ?? [];
    return questionOptionsPaginator.value?.response?.filter((q) => q?.question_type !== 'text')
      ?.map((questionObj) => {
        const { value, label, sidelabel, error } = questionOption(questionObj, true); // ! can’t get error from questionOption, only from questionObj
        if (value == null || error !== undefined) return null;
        return {
          value,
          label,
          sidelabel,
          icon: 'zmdi-check-all',
          sublist: (answerKey) => answerValuesOptionsFn(answerKey, questionObj),
        };
      })
      .filter((opt) => opt !== null) || [];
  } catch (err) {
    console.error('[TC] questionOptions error:', err); // eslint-disable-line no-console
  }
  return [];
}, []);

// ? TAGS (KEYS -> VALUES) WITH FRESH-PAGINATORS
const isLoadingMoreTagKeys = ref(false);
const isLoadingTagKeys = computedAsync(
  async () => {
    await store.getters.fetchingTagKeys;
    return false;
  },
  true,
);
const isFetchingTagValues = computedAsync(
  async () => {
    await store.getters.fetchingTags;
    return false;
  },
  true,
);

const debouncedFetchTagValues = debounce(
  (tagKey, term) => store.dispatch('fetchTagValues', { tagKey, searchTerm: term }),
  707,
);
const wrapTagValuesResultsFn = (response) => {
  if (response?.results?.length > 0) {
    return {
      ...response,
      results: response.results.map((opt) => ({
        value: opt,
        label: translateTerm(opt),
        icon: 'zmdi-label',
      })),
    };
  }
  return { next: null, prev: null, results: [], ...response };
};
const tagValuesOptionsPopulators = ref(new Map());
const tagValuesOptionsFn = (tagKey, skipFetch = false) => {
  const emptyOpts = [{
    boxRowType: 'tags',
    groupSlug: tagKey,
    groupTitle: '',
    loading: true,
    options: [],
  }];
  if (skipFetch || isFetchingTagValues.value || !tagKey) return emptyOpts;

  const usedTagKey = searchTerm.value ? `${tagKey}-${searchTerm.value}` : tagKey;
  if (!tagValuesOptionsPopulators.value.has(usedTagKey)) {
    const response = store.getters.segmentTagValuesPaginator(usedTagKey);
    const populator = new SearchPopulator(
      new RequestPaginator(response, wrapTagValuesResultsFn),
      searchFn,
    );
    if (Object.keys(response).length > 0) {
      populator.loadMore();
      tagValuesOptionsPopulators.value.set(usedTagKey, populator);
    } else {
      debouncedFetchTagValues(tagKey, searchTerm.value); // Will trigger the `store.getters.segmentTagValuesPaginator(usedTagKey)` reactively
      return emptyOpts;
    }
  }

  const tagValuesOptionsPopulator = tagValuesOptionsPopulators.value.get(usedTagKey);
  tagValuesOptionsPopulator.filterStack();
  return [{
    boxRowType: 'tags',
    groupSlug: tagKey,
    groupTitle: '',
    loading: isFetchingTagValues.value || tagValuesOptionsPopulator?.loading || false,
    hasMore: tagValuesOptionsPopulator?.hasMore?.() || false,
    options: tagValuesOptionsPopulator?.filteredStack?.map((opt) => ({
      ...opt,
      selected: (props.badgeItem?.label === tagKey && props.badgeItem?.value?.includes(opt.value)),
    })).map(markLabel(searchTerm.value)) || [],
  }];
};

const wrapTagKeysResultsFn = (response) => {
  if (response?.results?.length > 0) {
    return {
      ...response,
      results: response.results.map((opt) => ({
        value: opt.key ?? opt.value,
        label: opt.translation ?? opt.value,
        icon: 'zmdi-labels',
        sublist: tagValuesOptionsFn,
      })),
    };
  }
  return { next: null, prev: null, results: [], ...response };
};
const tagKeyOptionsPopulator = computed(() => {
  if (isLoadingTagKeys.value) return {};
  const response = store.getters.segmentTagKeysPaginator;
  const paginator = new RequestPaginator(response, wrapTagKeysResultsFn);
  const populator = new SearchPopulator(paginator, searchFn);
  populator.loadMore();
  return populator ?? {};
});

const tagKeyOptions = computed(() => {
  if (isLoadingTagKeys.value) return [];
  return tagKeyOptionsPopulator.value?.filteredStack.map(markLabel(searchTerm.value)) ?? [];
});

const loadMore = async (groupSlug) => {
  const usedTagKey = searchTerm.value ? `${props.includeGroup}-${searchTerm.value}` : props.includeGroup;
  const tagValuePopulator = tagValuesOptionsPopulators.value.get(usedTagKey);
  if (tagValuePopulator?.hasMore?.() === false || tagKeyOptionsPopulator.value?.hasMore?.() === false && groupSlug === 'tags') return;
  if (tagValuePopulator?.hasMore?.() && groupSlug !== 'tags') await tagValuePopulator.loadMore();
  else if (tagKeyOptionsPopulator.value?.hasMore?.()) {
    isLoadingMoreTagKeys.value = true;
    await tagKeyOptionsPopulator.value.loadMore();
    store.dispatch('setTagKeys', {
      tagKeysPaginator: tagKeyOptionsPopulator.value._paginator._dataset, // Depends on layers of paginator classes
      customer: false,
    });
    isLoadingMoreTagKeys.value = false;
  }
};

const questionObj = ref({});
watch(
  () => props.includeGroup,
  (newVal, oldVal) => {
    if (newVal && newVal !== oldVal) {
      store.dispatch('getQuestionById', Number(props.includeGroup))
        .then((resp) => { questionObj.value = resp; });
    }
  },
  { immediate: true },
);

const isModalCard = computed(() => store.getters?.modalCard?.metadata?.graphType !== undefined);

const topicable = computed(
  () => isTopicable(store.getters.modalCard?.metadata?.question)
    && store.getters.modalCard?.metadata?.graphType?.selected === GRAPH_TYPES.freeText,
);

const getQuestion = async (questionId) => store.dispatch('getQuestionById', questionId);
const question = computedAsync(async () => {
  if (isModalCard.value) { return getQuestion(store.getters.modalCard?.metadata?.question); }
  return {};
}, {});

const allowlist = computed(() => {
  if (!isModalCard.value) return undefined;
  if (isKeyMetricCard.value) return KEY_METRIC_ALLOWLIST_BADGES[store?.getters?.modalCard?.metadata?.graphType?.selected]; // eslint-disable-line max-len
  if (isGoal.value) return allFilters;
  if (!question.value?.question_type) return [];
  if (topicable.value) return QUESTION_ALLOWLIST_BADGES[question.value?.question_type];
  return QUESTION_ALLOWLIST_BADGES[question.value?.question_type].filter((opt) => opt !== 'topics');
});

const groups = computed(() => {
  const allGroups = [
    {
      groupSlug: '',
      groupTitle: '',
      loading: false,
      options: [
        {
          value: 'segment',
          label: $gettext('Segment'),
          icon: 'zmdi-group-work',
          sublist: () => ([{
            groupSlug: 'segment', // ? Used as key for DB metadata matching
            groupTitle: '',
            loading: false,
            options: segmentOptions?.value?.map((opt) => ({
              ...opt,
              selected: (props.badgeItem?.boxRowKey === 'segment' && props.badgeItem?.boxRowValue?.includes(opt.value)),
            })) || [],
          }]),
        },
        ...(isKeyMetricCard.value ? [{
          value: 'step',
          label: $gettext('Formulär'),
          icon: 'zmdi-forms-page',
          sublist: () => ([{
            groupSlug: 'step', // ? Used as key for DB metadata matching
            groupTitle: '',
            loading: false,
            options: stepOptions?.value?.map((opt) => ({
              ...opt,
              multiselect: (!KEY_METRIC_ALLOWLIST_LEVELS?.[store.getters.modalCard.metadata.graphType.selected]?.stepRequired), // eslint-disable-line max-len
              selected: (props.badgeItem?.boxRowKey === 'step' && props.badgeItem?.boxRowValue?.includes(opt.value)),
            })) || [],
          }]),
        }] : []),
        {
          value: 'customerProxies',
          label: $gettext('Proxies'),
          icon: 'zmdi-input-antenna',
          sublist: () => ([{
            groupSlug: 'customerProxies', // ? Used as key for DB metadata matching
            groupTitle: '',
            loading: isLoadingProxies.value,
            options: customerProxiesOptions?.value?.map((opt) => ({
              ...opt,
              selected: (props.badgeItem?.boxRowKey === 'customerProxies' && props.badgeItem?.boxRowValue?.includes(opt.value)),
            })) || [],
          }]),
        },
        {
          value: 'topics',
          label: $gettext('Textkategorier'),
          icon: 'zmdi-circle',
          sublist: () => ([{
            groupSlug: 'topics', // ? Used as key for DB metadata matching
            groupTitle: '',
            loading: false,
            options: topicOptions?.value?.map((opt) => ({
              ...opt,
              selected: (props.badgeItem?.boxRowKey === 'topics' && props.badgeItem?.boxRowValue?.includes(opt.value)),
            })) || [],
          }]),
        },
      ],
    },
    {
      groupSlug: 'tags',
      groupTitle: $gettext('Tags'),
      loading: isLoadingMoreTagKeys.value || isLoadingTagKeys.value || tagKeyOptionsPopulator.value?.loading === true,
      hasMore: tagKeyOptionsPopulator.value?.hasMore?.() || false, // TODO Validate
      options: tagKeyOptions.value || [],
    },
    {
      groupSlug: 'answers',
      groupTitle: $gettext('Svar'), // We should translate this
      loading: isLoadingQuestions.value || questionOptionsPaginator.value?.loading || false,
      options: questionOptions.value.filter(searchFn).map(markLabel(searchTerm.value)) || [],
    },
  ];

  let allAllowedGroups = allGroups;

  if (allowlist.value) {
    allAllowedGroups = allGroups.reduce((acc, group) => {
      if (!group.groupSlug) {
        group.options = group.options.filter((option) => {
          if (option.sublist) {
            const subgroups = option.sublist(option.value, true);
            option.sublist = () => subgroups.filter((subgroup) => {
              if (allowlist.value.includes(subgroup.groupSlug)) return true; // Check sublist
              return false;
            });
          }
          if (allowlist.value.includes(option.value)) return true; // Check option
          return false;
        });
        return [...acc, group];
      }
      if (allowlist.value.includes(group.groupSlug)) return [...acc, group];
      return acc;
    }, []);
  }

  if (props.includeGroup || props.excludeGroups?.length > 0) {
    // TODO: Currently losing reactivity, doesn’t update when if loading changes
    if (props.includeGroup) {
      const flattenedIntoOptions = allAllowedGroups.reduce((acc, group) => [...acc, ...group.options], []);
      const finding = flattenedIntoOptions.find((opt) => opt.value === props.includeGroup);
      if (props.badgeItem.boxRowType === 'answers') return answerValuesOptionsFn(props.includeGroup, questionObj.value) ?? [];
      if (props.badgeItem.boxRowType === 'tags') return tagValuesOptionsFn(props.includeGroup) ?? [];
      if (finding?.loading) return [];
      return finding?.sublist?.(finding?.value) ?? [];
    }

    if (props.excludeGroups?.length > 0) {
      return allAllowedGroups.filter((group) => {
        if (props.excludeGroups.includes(group.groupSlug)) return false; // Check group
        group.options = group.options.filter((option) => {
          if (option.sublist) {
            const subgroups = option.sublist(option.value, true);
            option.sublist = () => subgroups.filter((subgroup) => {
              if (props.excludeGroups.includes(subgroup.groupSlug)) return false; // Check sublist
              return true;
            });
          }
          if (props.excludeGroups.includes(option.value)) return false; // Check option
          return true;
        });
        return true;
      });
    }
  }
  return allAllowedGroups;
});

const updateOperator = (_operator) => {
  if (props.badgeItem.operator === _operator) return;

  const type = props.badgeItem?.boxRowType || props.includeGroup;
  const tag = props.includeGroup;
  emit('update:operator', { type, tag, operator: _operator });
};

</script>
