<template>
  <li
    ref="barGraphRowEl"
    v-tooltip="{
      content: canDrilldown
        ? $pgettext(
          'Tooltip — Row drilldownable',
          'Klicka för att djupdyka i ”%{rowLabel}”',
          { rowLabel: row.label || $pgettext('Tooltip — Row drilldownable fallback label', 'denna raden') }
        )
        : '',
      container: 'body',
      delay: { show: 1500, hide: 0 },
      placement: 'auto-start',
    }"
    class="tc-list-group-item tc-list-group-item-ultraslim tc-list-group-item-hover px-2"
    @mouseleave="destroyTooltip($refs?.barGraphRowEl)"
    @mouseover="createTooltip($refs?.barGraphRowEl, {
      content: () => getTooltipContent(row, categories),
      container: 'body',
      html: true,
    })"
    @click.stop="canDrilldown ? onClickLabel() : null"
  >
    <h5
      class="mb-0"
      :class="{ 'tc-loading-text tc-loading-text-on-color size-small': skeletonLoader }"
    >
      <i-material-symbols-arrow-top-right-rounded
        v-if="canDrilldown"
        class="drill-icon"
      />
      <i
        v-if="questionType === 'text' && row?.topic"
        :class="`zmdi zmdi-circle mr-0.5 bar-graph-topic--${row.topic}`"
      />
      <span>{{ row.label }}</span>
      <span
        v-if="row.count"
        class="hide-screen show-print ml-0.5"
      >({{ row?.count?.[0] || 0 }} <span>{{ $pgettext("Print — Bar graph count", "svar") }}</span>)</span>
    </h5>
    <div class="bar-graph-indicators">
      <bar-graph-indicator
        ref="BarGraphIndicatorEl"
        :custom-style="customStyle"
        :style="wrapperStyle"
        :nps-color="npsColor"
        :data="row?.data || []"
        :count="row?.count || []"
        :width="row?.width || []"
        :skeleton-loader="skeletonLoader || null"
      />
    </div>
  </li>
</template>

<script>
import { isArray, isNaN } from 'lodash-es';
import { mapGetters, mapActions } from 'vuex';
import gaPush from '@/plugins/googleAnalytics';
import { NPS_NAMES } from 'Utils/question';
import { sequentialCerulean } from 'Utils/chartColors';
import { createTooltip, destroyTooltip } from 'Components/parts/widgets/PerformantTooltip';
import BarGraphIndicator from 'Components/parts/graph/BarGraphIndicator';

export default {
  name: 'BarGraphRow',
  components: {
    BarGraphIndicator,
  },
  props: {
    questionType: String,
    row: {
      /**
      ** row
      * @param {Array} data, the value to base the width on, used for width if not row.width is set
      * @param {Array} count, the value that implies the strength
      * @param {Array} [width], optional value used for another scale than data
      * @param {String} label, the title to set for the indicator
      * @param {String} [topic], optional topic name for certain color scheme
      * @param {String} [color], optional hex-color  for specific color scheme
      */
      type: Object,
      validator: (row) => isArray(row.data),
    },
    categories: Array,
    skeletonLoader: {
      type: Boolean,
      default: false,
    },
  },
  expose: ['resizeIndicator'],
  computed: {
    ...mapGetters(['customerName', 'allOpenPanes', 'isEditMode']),
    wrapperStyle() {
      if (this.row?.color && [this.customerName, 'Global', 'Benchmark'].includes(this.row.label)) {
        return `background-color: ${this.row.color}11;`;
      }
      return '';
    },
    customStyle() {
      if (this.row?.color) return `background: ${this.row.color}; background: linear-gradient(to right, ${this.row.color}, ${this.row.color}cc); border:${this.row.color} 2px solid;`;
      return this.questionType === 'rating' ? `background: ${sequentialCerulean[this.row.label]}; border:${sequentialCerulean[this.row.label]} 2px solid;` : '';
    },
    npsColor() {
      if (this.questionType === 'cnps') {
        const { detractor, promoter, passive } = NPS_NAMES();
        switch (this.row.label) {
          case detractor:
            return 'bar-graph-indicator-detractor';
          case promoter:
            return 'bar-graph-indicator-promoter';
          case passive:
            return 'bar-graph-indicator-passive';
          default:
            return '';
        }
      }
      return '';
    },
    hasMatchingDrilldownAlready() {
      return this.allOpenPanes.some((pane) => pane.drilldownData?.slug === this.row.drilldownData?.slug);
    },
    canDrilldown() {
      return !this.isEditMode
        && !this.hasMatchingDrilldownAlready
        && this.row?.drilldownData?.boxRowValue !== undefined;
    },
  },
  methods: {
    ...mapActions(['openPane']),
    createTooltip,
    destroyTooltip,
    resizeIndicator() {
      this.$refs?.BarGraphIndicatorEl.handleResize();
    },
    onClickLabel() {
      if (!this.canDrilldown) return;
      gaPush({
        event: '[TC] Drilled down',
        eventAction: 'Drilled down',
        eventCategory: 'Feature Use',
        eventLabel: 'Drilled down in BarGraphRow',
        hitType: 'event',
      });
      this.openPane({
        name: `drilldown-${this.allOpenPanes.length + 1}`,
        drilldownData: this.row?.drilldownData,
        callback: () => {},
      });
    },
    getTooltipContent(row, categories) {
      return categories.map((category, i) => {
        if (row.data[i] === undefined && row.count[i] === undefined) return '';
        const count = row?.count?.[i] ?? 0;
        const totalCount = row?.totalCount?.[i] ?? row?.totalCount ?? 0;
        let theCounts = '';
        if (count && !totalCount) theCounts = ` (${count} <span>${this.$pgettext('Popover — Bar graph count', 'svar')}</span>)`;
        if (totalCount) theCounts = ` (${count} / ${totalCount} <span>${this.$pgettext('Popover — Bar graph count', 'svar')}</span>)`;

        if (isNaN(row.data[i])) {
          return `
          <p class="bar-graph-legend">
            <i class="zmdi zmdi-circle mr-0.5" style="${row.color ? `color: ${row.color}` : ''}"></i>
            ${category || row.label} — <strong>${this.$pgettext('Popover — Bar graph NaN', 'Inget värde')}</strong>
          </p>`;
        }
        return `
        <p class="bar-graph-legend">
          <i class="zmdi zmdi-circle mr-0.5" style="${row.color ? `color: ${row.color}` : ''}"></i>
          ${category || row.label} — <strong>${row.data[i] ?? 0}%</strong>${theCounts}
        </p>`;
      }).join('');
    },
  },
};
</script>
