<template>
  <div
    v-tc-loader-bar="loading"
    class="query-result-table tc-loader-bar-wrapper check-scrollbar"
    :class="classes"
  >
    <div
      v-if="errorMessage"
      class="p-card text-center"
    >
      <p class="alert alert-info inline-block">
        <i class="zmdi zmdi-info" />
        <span>{{ errorMessage }}</span>
      </p>
    </div>
    <template v-else>
      <div class="tc-card-message-wrapper">
        <div
          v-if="showNoDataMessage"
          class="tc-card-message-overlay tc-card-message-overlay-tight p-card"
        >
          <p class="alert alert-info">
            <i class="zmdi zmdi-info" />
            <span>{{ $gettext('Det finns ingen data. Prova att ändra filter.') }}</span>
            <br>
            <span v-if="hasAnonRows && hasErroredRows">
              {{ $gettext('Eller visa de gömda raderna genom att ändra inställningar för kortet') }}
            </span>
            <span v-else-if="hasAnonRows && !hasErroredRows">
              {{ $gettext('Eller visa de gömda anonymiserade raderna genom att ändra inställningar för kortet') }}
            </span>
            <!-- eslint-disable -->
          <span v-else-if="!hasAnonRows && hasErroredRows">
            {{ $gettext('Eller visa de gömda raderna som inte fått tillräckligt med svar genom att ändra inställningar för kortet') }}
          </span>
          <!-- eslint-enable  -->
          </p>
        </div>
        <div class="tc-card-message-underlay">
          <div
            ref="tableWrapper"
            class="tc-table-wrapper"
            :tabindex="disableTabindex ? -1 : null"
            @scroll="hasScroll($event)"
          >
            <table
              v-if="hasData"
              class="tc-table tc-table-hover tc-table-cell-width tc-table-hover--drillable"
            >
              <qr-thead
                ref="thead"
                v-model:rows="rows"
                v-model:benchmarks="benchmarks"
                :label-header="labelHeader"
                :label-header-translation-error="labelHeaderTranslationError"
                :columns="columns"
                :sort-settings="sortSettings"
                @update:sort="updateSort"
              />
              <tbody>
                <qr-tr
                  v-for="(row, i) in rows"
                  :key="row.label"
                  :show="card?.metadata.show || {}"
                  :row="row"
                  :benchmark="benchmarks[i]"
                  :benchmark-cols="benchmarkCols"
                  :columns="columns"
                  :focused="row.label === focusedRow"
                  :active="row.label === stateActiveRow"
                  :async-confidence-popover-fn="asyncConfidencePopoverFn"
                  @update-drag-area="$emit('update-drag-area')"
                />
              </tbody>
              <qr-tr-total
                v-if="!hideTotals || showFooter"
                :rows="rows"
                :columns="columns"
                :benchmarks="benchmarks"
                :benchmark-cols="benchmarkCols"
                :segment-total="segmentTotal"
                :question="question"
                :reverse-stats="reverseStats"
                :hide-totals="hideTotals"
                :total-count="totalCount"
                :total-row-average="totalRowAverage"
                :show="card?.metadata.show || {}"
                :benchmark-company-total="benchmarkCompanyTotal"
                :benchmark-global-total="benchmarkGlobalTotal"
                :total-distribution="totalDistribution"
              />
            </table>
            <table
              v-else
              class="tc-table tc-table-hover tc-table-cell-width"
            >
              <thead>
                <tr>
                  <th>
                    {{ labelHeader ?? '—' }}
                  </th>
                  <th
                    v-for="column in placeholderColumns"
                    :key="column.label"
                    :class="cellClasses(column)"
                  >
                    {{ column.label }}
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="row in placeholderRows"
                  :key="row.label"
                  class="loading"
                >
                  <td>
                    <span
                      class="tc-loading-text"
                      v-html="row?.label ?? '—'"
                    />
                  </td>
                  <td
                    v-for="column in placeholderColumns"
                    :key="column.label"
                    :class="cellClasses(column)"
                  >
                    <cnps-bar
                      v-if="column.value === 'cnpsBar'"
                      :stats="row.stats.stats ?? {}"
                    />
                    <strong v-else>{{ row.value }}</strong>
                  </td>
                </tr>
              </tbody>
              <tfoot v-if="!hideTotals || showFooter">
                <tr>
                  <td>
                    <span>{{ $pgettext('Label — Table total row', 'Total') }}</span>
                  </td>
                  <td
                    v-for="column in placeholderColumns"
                    :key="column.label"
                    :class="cellClasses(column)"
                  >
                    <cnps-bar
                      v-if="column.value === 'cnpsBar' && !hideTotals"
                      :stats="{
                        detractor: 2,
                        passive: 10,
                        promoter: 18
                      }"
                    />
                    <span v-else>
                      —
                    </span>
                  </td>
                </tr>
                <tr class="show-if-scrollbar">
                  <td />
                  <td
                    v-for="column in placeholderColumns"
                    :key="column.label"
                    :class="cellClasses(column)"
                  />
                </tr>
              </tfoot>
            </table>
          </div>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import {
  forOwn, mapValues, pick, values, isArray, isEmpty, isBoolean, throttle,
} from 'lodash-es';
import { mapGetters, mapActions, mapState } from 'vuex';
import { format, endOfMonth, isMatch } from 'date-fns';
import { requestConfidence } from 'API/analysis';
import eventBus from 'Utils/eventBus';
import { rowSort } from 'Utils/stats';
import { show, GRAPH_TYPES } from 'Utils/graph';
import {
  translateBaseValue, addEvent, removeEvent, prefixNPSValue, equalLiteral, roundNumber, toTotallyFixed, globalTuplets,
} from 'Utils/general';
import { convertDateObjectToAbsoluteDates } from 'Utils/date';
import CnpsBar from 'Components/parts/graph/CNPSBar';
import QueryResultTableRow from './QueryResultTableRow';
import QueryResultTableTotalRow from './QueryResultTableTotalRow';
import QueryResultTableHeader from './QueryResultTableHeader';

const COLUMN_ORDER = ['cnpsBar', 'cnps', 'baseValues', 'average', 'benchmarks', 'count'];
const NO_VALUE = '–';

export default {
  name: 'QueryResultTable',
  components: {
    CnpsBar,
    QrTr: QueryResultTableRow,
    QrTrTotal: QueryResultTableTotalRow,
    QrThead: QueryResultTableHeader,
  },
  props: {
    board: Object, // Unused
    card: {
      type: Object,
      required: true,
    },
    columnLabels: Object,
    columnTitles: Object,
    question: Object,
    loading: { type: Boolean, default: false },
    disableTabindex: {
      type: Boolean,
      default: false,
    },
    toqResponse: Array,
    toqGlobalResponse: Array,
    currentFilter: Object,
    contextFilter: Object,
    compiledFilter: Object,
    compiledBenchmark: Object, // Unused
    compiledCompare: [Object, null],
    labelHeader: String,
    labelHeaderTranslationError: [Error, String, null],
    allNull: Boolean,
    temporaryCard: Boolean,
    errorMessage: String,
  },
  emits: ['update-drag-area'],
  data() {
    return {
      rows: [],
      totalCount: [],
      benchmarks: [],
      focusedRow: '',
      reverseStats: null,
      loadingBenchmarks: false,
      showingAnyRows: false,
      hasRegularRows: true,
      hasShowableRows: true,
      hasAnonRows: false,
      hasErroredRows: false,
      abortToken: Math.random().toString(10).substring(2),
    };
  },
  computed: {
    ...mapGetters([
      'customerId',
      'segmentId',
      'segmentFilter',
      'modalCard',
      'isEditMode',
    ]),
    ...mapState({
      stateActiveRow: (state) => state.cards.activeRow,
    }),
    sortSettings() {
      return this.card.metadata.graphType.settings.sort || {};
    },
    placeholderColumns() {
      if (this.columns.length > 0) return this.columns;
      if (this.question?.options?.cnps) return [{ label: this.$pgettext('Placeholder — Temp table column', 'Värde'), value: 'cnpsBar' }, { label: this.$pgettext('Placeholder — Temp table column', 'Antal'), value: 'cnpsBar' }];
      return [{ label: this.$pgettext('Placeholder — Temp table column', 'Värde'), value: '—' }, { label: this.$pgettext('Placeholder — Temp table column', 'Antal'), value: '—' }];
    },
    placeholderRows() {
      if (this.rows.length > 0) return this.rows.slice(0, 3); // ? Max 3 rows
      if (this.question?.options?.cnps) {
        return [
          { label: '2022-09', stats: { anonymized: false, average: 0.7, cnps: { displayValue: '+67', value: 67 }, count: 3, displayValues: { passive: 1, promoter: 2, label: '2022-09', average: 0.7, cnps: '+67', count: '3', percentages: { passive: '33.3%', promoter: '66.7%' } }, error: false, reverseStats: null, filter: {}, label: '2022-09', percentages: { passive: { displayValue: '33.3%', value: 0.333 }, promoter: { displayValue: '66.7%', value: 0.667 } }, question: 405, sortValues: { passive: 1, promoter: 2, label: '2022-09', count: 3, cnps: 67, average: 0.7, percentages: { passive: 0.333, promoter: 0.667 } }, stats: { passive: 1, promoter: 2 } } },
          { label: '2022-08', stats: { anonymized: false, average: 0.4, cnps: { displayValue: '+41', value: 41 }, count: 29, displayValues: { detractor: 4, passive: 9, promoter: 16, label: '2022-08', average: 0.4, cnps: '+41', count: '29', percentages: { detractor: '13.8%', passive: '31.0%', promoter: '55.2%' } }, error: false, reverseStats: null, filter: {}, label: '2022-08', percentages: { detractor: { displayValue: '13.8%', value: 0.138 }, passive: { displayValue: '31.0%', value: 0.31 }, promoter: { displayValue: '55.2%', value: 0.552 } }, question: 405, sortValues: { detractor: 4, passive: 9, promoter: 16, label: '2022-08', count: 29, cnps: 41, average: 0.4, percentages: { detractor: 0.138, passive: 0.31, promoter: 0.552 } }, stats: { detractor: 4, passive: 9, promoter: 16 } } },
        ];
      }
      return [
        { label: '2022-09', stats: { anonymized: false, average: 8.5, cnps: { displayValue: '±0', value: 0 }, count: 19, displayValues: { 5: 2, 7: 1, 8: 5, 9: 6, 10: 5, label: '2022-09', average: 8.5, cnps: '±0', count: '19', percentages: { 5: '10.5%', 7: '5.3%', 8: '26.3%', 9: '31.6%', 10: '26.3%' } }, error: false, reverseStats: null, filter: {}, label: '2022-09', percentages: { 5: { displayValue: '10.5%', value: 0.105 }, 7: { displayValue: '5.3%', value: 0.053 }, 8: { displayValue: '26.3%', value: 0.263 }, 9: { displayValue: '31.6%', value: 0.316 }, 10: { displayValue: '26.3%', value: 0.263 } }, question: 1354, sortValues: { 5: 2, 7: 1, 8: 5, 9: 6, 10: 5, label: '2022-09', count: 19, cnps: 0, average: 8.5, percentages: { 5: 0.105, 7: 0.053, 8: 0.263, 9: 0.316, 10: 0.263 } }, stats: { 5: 2, 7: 1, 8: 5, 9: 6, 10: 5 } } },
        { label: '2022-08', stats: { anonymized: false, average: 9.2, cnps: { displayValue: '±0', value: 0 }, count: 22, displayValues: { 7: 2, 8: 2, 9: 7, 10: 11, label: '2022-08', average: 9.2, cnps: '±0', count: '22', percentages: { 7: '9.1%', 8: '9.1%', 9: '31.8%', 10: '50.0%' } }, error: false, reverseStats: null, filter: {}, label: '2022-08', percentages: { 7: { displayValue: '9.1%', value: 0.091 }, 8: { displayValue: '9.1%', value: 0.091 }, 9: { displayValue: '31.8%', value: 0.318 }, 10: { displayValue: '50.0%', value: 0.5 } }, question: 1354, sortValues: { 7: 2, 8: 2, 9: 7, 10: 11, label: '2022-08', count: 22, cnps: 0, average: 9.2, percentages: { 7: 0.091, 8: 0.091, 9: 0.318, 10: 0.5 } }, stats: { 7: 2, 8: 2, 9: 7, 10: 11 } } },
      ];
    },
    compareTag() { return this.compiledCompare?.tag; },
    compareKey() { return this.compiledCompare?.key; },
    compareIsDate() { return this.compareKey === undefined && this.compareTag === undefined; },
    hideTotals() { return this.allNull; }, // ? if toqResults[0] only contains null records
    sortCol() {
      if (this.compareTag || this.compareKey) return this.question?.options?.cnps ? 'cnps' : 'average';
      return 'label';
    },
    filter() { return this.card?.metadata && { ...this.card.metadata.filter, level: this.card.metadata.level } || {}; },
    // questionId() { return this.card.metadata.question; },
    // showHeader() { return this.show(['count', 'total', 'average', 'cnps', 'benchmarks'], 'or'); },
    showFooter() { return this.card?.metadata?.show?.columns?.benchmarks || true; },
    hasData() { return this.rows.length > 0 && !this.allNull; },
    hasEnoughData() {
      if (!this.rows.length) return false;
      return this.rows.reduce((acc, row) => {
        if (row.stats.count === undefined) return acc;
        return acc + row.stats.count;
      }, 0) > 3;
    },
    segmentBasics() {
      const segmentBasics = this.toqResponse?.[0] || {};
      if (isEmpty(segmentBasics)) return [];
      if (this.compareTag) {
        return segmentBasics[this.compareTag]
          ? values(mapValues(segmentBasics[this.compareTag], (v) => ({
            label: v.value,
            count: v?.respondent_count ?? v?.count ?? null,
            score: v.score,
            reverseStats: isBoolean(v.reverse_stats) ? v.reverse_stats : null,
          })))
          : [];
      }
      return Object.keys(segmentBasics).map((groupByLabel, i) => ({
        label: groupByLabel,
        count: segmentBasics[groupByLabel]?.respondent_count ?? segmentBasics[groupByLabel]?.count ?? null,
        score: segmentBasics[groupByLabel]?.score ?? null,
        reverseStats: segmentBasics[groupByLabel]?.reverse_stats ?? null,
        groupById: segmentBasics[groupByLabel]?.customerproxy_id ?? segmentBasics[groupByLabel]?.segment_id ?? null,
      }));
    },
    segmentDetails() {
      const segmentDetails = this.toqResponse?.[1];
      if (
        segmentDetails === undefined
        || isEmpty(segmentDetails)
        || (this.compareTag !== undefined && segmentDetails[this.compareTag] === undefined)
      ) return [];

      if (
        (this.question.options?.cnps
      || this.question.question_type === 'listmany'
      || this.question.question_type === 'list'
      || this.question.question_type === 'yesno')
      && this.compareTag
      ) {
        // todo: maybe check for reverseStats if yesno
        const cnpsArray = segmentDetails[this.compareTag];
        const tagValues = this.segmentBasics.map((o) => o.label);
        const scoresArray = tagValues
          .reduce((acc, tag) => {
            const obj = cnpsArray[tag];
            return [...acc, { label: tag, ...mapValues(obj, (v) => v?.respondent_count ?? v?.count ?? null) }];
          }, []);
        return scoresArray;
      }
      return Object.keys(segmentDetails).map((groupByLabel, i) => {
        let scores = {};
        forOwn(segmentDetails[groupByLabel], (val, key) => {
          if (val?.respondent_count !== null) scores[key] = val?.respondent_count ?? null; // don't include it if count is null (anonymized)
          else if (val?.count !== null) scores[key] = val?.count ?? null; // don't include it if count is null (anonymized)
        });
        return { ...scores, label: groupByLabel };
      });
    },
    benchmarkCompany() {
      const benchmarksCompany = this.toqResponse[2];
      if (this.compareIsDate) {
        return Object.keys(benchmarksCompany).map((groupByLabel, i) => {
          let benchmarkRow = benchmarksCompany[groupByLabel];
          if (this.question.question_type === 'yesno') {
            return {
              label: groupByLabel,
              score: this.wrapTuplet(benchmarkRow),
            };
          }
          return ({
            label: groupByLabel,
            score: benchmarkRow?.score ?? null,
            count: benchmarkRow?.respondent_count ?? benchmarkRow?.count ?? null,
          });
        });
      }
      // eslint-disable-next-line max-len
      return new Array(this.segmentBasics.length).fill({ score: this.benchmarkCompanyTotal.average, count: this.benchmarkCompanyTotal.count });
    },
    benchmarkCompanyTotal() {
      const res = this.toqResponse?.[3];
      const average = this.question?.options?.cnps && res?.score ? res.score * 100 : res?.score ?? null;
      const count = res?.respondent_count ?? res?.count ?? null;
      return {
        average,
        count,
      };
    },
    segmentTotal() {
      // ? If we need to change totalCount the logic should happen here
      if (this.toqResponse?.[4] && !isArray(this.toqResponse[4])) {
        return {
          average: this.toqResponse[4].score,
          count: this.toqResponse[4].respondent_count ?? this.toqResponse[4].count,
        };
      }
      return { average: null, count: 0 };
    },
    totalDistribution() {
      if (
        this.question.options?.cnps && this.toqResponse?.[5]
      || this.question.question_type === 'listmany' && this.toqResponse?.[5]
      || this.question.question_type === 'list' && this.toqResponse?.[5]
      ) return this.toqResponse[5];
      return {};
    },
    benchmarkGlobal() {
      const benchmarksGlobal = this.toqGlobalResponse?.[0] ?? null;
      // if compare by proxies or segment then toqGlobalResponse[0] is {count:1231, score:0.7897}
      if (this.compareIsDate) {
        return Object.keys(benchmarksGlobal).map((groupByLabel, i) => {
          let benchmarkRow = benchmarksGlobal[groupByLabel];
          if (this.question.question_type === 'yesno') {
            return {
              label: groupByLabel,
              score: globalTuplets(benchmarkRow, this.reverseStats),
            };
          }
          return ({
            label: groupByLabel,
            score: benchmarkRow?.score ?? null,
            count: benchmarkRow?.count ?? null,
          });
        });
      }
      return new Array(this.segmentBasics.length).fill(benchmarksGlobal);
    },
    benchmarkGlobalTotal() {
      const globRes = this.toqGlobalResponse?.[1];
      const average = this.question?.options?.cnps && globRes?.score ? globRes.score * 100 : globRes?.score ?? null;
      const count = globRes?.count ?? null;
      return { average, count };
    },
    totalRowAverage() {
      if (this.question.question_type === 'yesno' && this.toqResponse?.[4]) {
        return globalTuplets(this.toqResponse[4], this.reverseStats);
      }
      return null;
    },
    benchmarkCols() {
      // ? Comment out keys here to choose what benchmarks to be used
      return {
        global: {
          title: isEmpty(this.compiledFilter.benchmark) ? this.$gettext('Genomsnittet för Global data') : this.$gettext('Genomsnittet för Benchmark data'),
          classes: 'zmdi zmdi-globe',
          loading: this.loadingBenchmarks,
        },
        customer: {
          title: this.$gettext('Genomsnittet för ert företag (alla segment)'),
          classes: 'zmdi zmdi-city',
          loading: this.loadingBenchmarks,
        },
      };
    },
    showNoDataMessage() {
      return (
        !this.loading
        && !this.hasData
        && !this.hasEnoughData
      ) || (
        !this.loading
        && this.hasData
        && this.hasShowableRows
        && !this.hasRegularRows
        && !this.showingAnyRows
        && !this.hasEnoughData
      );
    },
    classes() {
      return `${!this.hasData ? 'query-result-table-no-data ' : ''}question-type-${this.question && this.question.question_type}`;
    },
    columns() {
      let cols = [];
      COLUMN_ORDER.forEach((setting) => {
        if (setting === 'baseValues' && this.show(setting)) {
          cols = [
            ...cols,
            ...this.card.metadata.show.columns.baseValues
              .map((value) => ({
                value,
                label: translateBaseValue(value, this.question),
                showPercentages: this.show('percentages'),
                numberCell: this.show('numberCell'),
                expandedHeader: this.show('expandedHeader'),
                anonymizedRows: this.show('anonymizedRows'),
                erroredRows: this.show('erroredRows'),
              })),
          ];
        } else if (this.show(setting)) {
          cols.push({
            value: setting,
            label: this.columnLabels[setting],
            title: this.columnTitles[setting] || this.columnLabels[setting],
          });
        }
      });
      return cols;
    },
  },
  watch: {
    hasData(newVal) {
      if (newVal && this.$refs.tableWrapper) {
        this.$nextTick(() => {
          this.hasScroll({ currentTarget: this.$refs.tableWrapper });
        });
      }
    },

    toqResponse: {
      deep: true,
      handler() { this.populateRows(); },
    },
    toqGlobalResponse: {
      deep: true,
      handler() { this.populateRows(); },
    },
    'card.metadata.show': {
      deep: true,
      handler() {
        this.checkForShowableRows();
        if (this.$refs.tableWrapper && !this.showNoDataMessage) {
          this.$nextTick(() => {
            this.hasScroll({ currentTarget: this.$refs.tableWrapper });
          });
        }
        this.$emit('update-drag-area');
      },
    },
    contextFilter(newFilter, oldFilter) {
      if (!equalLiteral(newFilter, oldFilter)) this.setup();
    },
    compiledFilter(newFilter, oldFilter) {
      if (!equalLiteral(newFilter, oldFilter)) this.setup();
    },
    compareTag() { this.setup(); },
    compareKey() { this.setup(); },
    rows: {
      deep: true,
      handler(newVal) {
        if (newVal.length > 0) {
          this.totalCount = newVal.map((v) => (v.stats.count === undefined ? 0 : v.stats.count));
        }
      },
    },
  },
  mounted() {
    this.$nextTick(() => {
      this.$$eventBus.$on('update:focus-row', this.focus);
      this.$$eventBus.$on('update:hover-row', this.active);
      this.setup();
    });
    addEvent(window, 'click', this.handleWindowClick);
    addEvent(window, 'keyup', this.handleWindowClick);
  },
  // updated() {
  //   this.$emit('update-drag-area');
  // },
  beforeUnmount() {
    this.$$eventBus.$off('update:focus-row');
    this.$$eventBus.$off('update:hover-row');
    eventBus.$emit(`abortRequest:${this.abortToken}`);
    removeEvent(window, 'click', this.handleWindowClick);
    removeEvent(window, 'keyup', this.handleWindowClick);
  },
  methods: {
    ...mapActions([
      'setActiveRow',
      'clearActiveRow',
      'setModalCardPath',
      'updateCard',
    ]),
    updateSort(payload) {
      const updatedGraphType = {
        ...this.card.metadata.graphType,
        settings: {
          ...this.card.metadata.graphType.settings,
          sort: payload,
        },
      };
      const metadata = { ...this.card.metadata, graphType: updatedGraphType };

      if (!this.temporaryCard && isEmpty(this.modalCard)) {
        const card = { ...this.card, metadata };
        this.updateCard({ card, apiCall: this.isEditMode });
        return;
      }
      if (!isEmpty(this.modalCard)) {
        const updatedModalCardSort = { ...this.modalCard.metadata.graphType.settings.sort, ...payload };
        this.setModalCardPath({
          path: 'metadata.graphType.settings.sort',
          value: updatedModalCardSort,
        });
        return;
      }
      eventBus.$emit('update:temporary-card-metadata', { metadata });
    },
    async asyncConfidencePopoverFn(rowLabel, columnValue) {
      let message = '';
      try {
        const filter = {
          ...this.currentFilter,
          ...(this.compareIsDate && { date: { type: 'absolute', dateGte: `${rowLabel}-01`, dateLte: format(endOfMonth(new Date(rowLabel)), 'yyyy-MM-dd') } }),
          ...(!this.compareIsDate && { applicant: {
            tags: {
              [this.compareTag]: rowLabel,
            },
          } }),
        };
        const resp = await requestConfidence({ filter, confidenceInterval: 80 });
        /* eslint-disable no-irregular-whitespace */
        if (!resp) {
          message = ('<span class="tc-color-red">Couldn’t get confidence interval. Hover to try again</span>');
          requestConfidence.delete({ filter }); // ? Delete the request from the memoized cache
        } else {
          const lower = columnValue === 'cnps' ? this.wrapCNPS(resp?.lower_bound ?? 0, true).displayValue : toTotallyFixed(resp?.lower_bound ?? 0, 2);
          const mean = columnValue === 'cnps' ? this.wrapCNPS(resp?.mean ?? 0, true).displayValue : toTotallyFixed(resp?.mean ?? 0, 2);
          const upper = columnValue === 'cnps' ? this.wrapCNPS(resp?.upper_bound ?? 0, true).displayValue : toTotallyFixed(resp?.upper_bound ?? 0, 2);
          message = `
            <div>
              <h4>${resp?.confidence_interval_percentage ?? '—'}% Confidence interval</h4>
              <p class="text-center">
                <span class="strong super-subtle-text">${lower ?? ''}  ⊢  </span>
                <span class="strong big-text">${mean ?? '▱'}</span>
                <span class="strong super-subtle-text">  ⊣  ${upper ?? ''}</span>
              </p>
            </div>
          `;
        }
        /* eslint-enable no-irregular-whitespace */
      } catch (err) {
        message = err;
      }
      return message;
    },
    cellClasses(column) {
      return {
        'tc-cnps-cell': column.value === 'cnps',
        'tc-cnps-bar-cell': column.value === 'cnpsBar',
        'tc-benchmarks-cell': column.value === 'benchmarks',
        'tc-table-number-cell': column.numberCell,
        'tc-table-expanded-header': column.numberCell && column.expandedHeader,
      };
    },
    onlyReturnUsedKeys(rows) {
      const tagKeyIsCompare = 'tags' in this.compiledFilter && Object.keys(this.compiledFilter.tags).length > 0 ? Object.keys(this.compiledFilter?.tags).filter((key) => key === this.compareTag) : false;
      if (tagKeyIsCompare && tagKeyIsCompare.length > 0) {
        const tagVals = this.compiledFilter?.tags[tagKeyIsCompare];
        return rows.filter((row) => tagVals.includes(row.label));
      }
      return rows;
    },
    mapPercentages(from = 'displayValue', row = {}) {
      let percentages = {};
      Object.keys(row.percentages).forEach((key) => {
        percentages[key] = row.percentages[key][from];
      });
      return percentages;
    },
    wrapTuplet(row) {
      let tuplet = mapValues(row, (val) => val.count);
      if (Object.keys(tuplet).length === 1) return mapValues(tuplet, (val) => 100);
      let tot = tuplet.ja + tuplet.nej;
      return mapValues(tuplet, (val) => val / tot * 100);
    },
    wrapPercentages(row) {
      if (row?.scores === undefined) return {};
      return mapValues(row.scores, (val) => {
        let num = roundNumber(val / row.count, 3);
        return ({ displayValue: `${(num * 100).toFixed(1)}%`, value: num });
      });
    },
    wrapCNPS(score) {
      let value = null;
      let displayValue = null;
      if (score !== null) {
        value = this.question?.options?.cnps ? roundNumber(score * 100, 2) : 0;
        displayValue = prefixNPSValue(value);
      }
      return { displayValue, value };
    },
    averageRating(score, type = 'number') {
      const roundedAverage = score !== null ? roundNumber(score, 1) : null;
      if (type === 'string') return Number.isInteger(roundedAverage) ? `${roundedAverage}.0` : `${roundedAverage}`;
      return roundedAverage;
    },
    hasScroll: throttle(function throttledFn({ currentTarget: el }) {
      const parent = el?.parentElement;
      if (parent && el) {
        if (el.scrollWidth > parent.clientWidth || el.scrollWidth > el.clientWidth) {
          parent.classList.add('has-scrollbar');

          if (el.scrollLeft > 5) parent.classList.add('has-scroll-left');
          else parent.classList.remove('has-scroll-left');

          if (el.scrollWidth - el.clientWidth - el.scrollLeft > 5) parent.classList.add('has-scroll-right');
          else parent.classList.remove('has-scroll-right');
        } else {
          parent.classList.remove('has-scrollbar');
        }
        this.$emit('update-drag-area');
      }
    }, 200),
    focus(row) {
      if (row === undefined) this.focusedRow = '';
      else this.focusedRow = row.label;
    },
    active(row) {
      if (row === undefined) this.clearActiveRow();
      else this.setActiveRow(row.label);
    },
    show(key) {
      return show(key, this.question, GRAPH_TYPES.queryTable, {
        ...this.card.metadata.show,
        ...this.card.metadata.show.columns,
        // ...this.card.metadata.show.rows,
      });
    },
    /**
     * TOQ v2 Response:
     * segmentBasics: [{label, count, score, reverse_stats}] – For yesno questions, true means that the stats are reversed: 1 means No
     * segmentDetails:[{label, score}]
     * benchmarkCompany: [{label, score, count}]
     * benchmarkCompanyTotal: [{average, count}]
     * segmentTotal: [{average, count}]
     * benchmarkGlobal[:{label, score, count}]
     * benchmarkGlobalTotal:[{average, count}]
     */
    populateRows() {
      if (this.toqResponse?.length > 0 && this.toqGlobalResponse?.length > 0) {
        let sortDesc = !this.compareIsDate;
        this.rows = this.segmentBasics.map((row, i) => {
          if (!isBoolean(this.reverseStats)) this.reverseStats = isBoolean(row.reverseStats) ? row.reverseStats : null;
          const segmentDetails = this.segmentDetails?.find((item) => item.label === row.label) || {};
          const segmentDetailsWithoutLabel = Object.entries(segmentDetails).reduce((acc, [itemKey, itemVal]) => {
            if (itemKey !== 'label') acc[itemKey] = itemVal;
            return acc;
          }, {});

          let drilldownData = { value: [row.label] };
          if (this.compareTag) {
            drilldownData = {
              slug: `tags__${this.compareTag}`,
              label: this.compareTag,
              value: [row.label],
              boxRowValue: [row.label],
              boxRowKey: this.compareTag,
              boxRowType: 'tags',
            };
          } else if (this.compareKey === 'segment') {
            drilldownData = {
              slug: 'segment',
              label: this.$pgettext('Badgelist', 'Segment'),
              value: [row.label],
              boxRowValue: [row.groupById],
              boxRowKey: 'segment',
              boxRowType: null,
            };
          } else if (this.compareIsDate && isMatch(row.label, 'yyyy-MM')) {
            const { dateLte, dateGte } = convertDateObjectToAbsoluteDates({ date: { type: 'absolute', dateLte: row.label, dateGte: row.label } });
            const date = { type: 'absolute', dateLte, dateGte };
            drilldownData = {
              slug: 'date',
              label: this.$pgettext('Badgelist', 'Datum'),
              value: [row.label],
              boxRowValue: date,
              boxRowKey: 'date',
              boxRowType: 'date',
            };
          } else if (this.compareKey === 'customerProxy') {
            drilldownData = {
              slug: 'customerProxies',
              label: this.$pgettext('Badgelist', 'Proxy'),
              value: [row.label], // label
              boxRowValue: [row.groupById],
              boxRowKey: 'customerProxy',
              boxRowType: null,
            };
          }

          return {
            drilldownData,
            label: row?.label,
            stats: {
              anonymized: row.score === null,
              average: Number.isFinite(row.score) ? this.averageRating(row.score) : null,
              cnps: Number.isFinite(row.score) ? this.wrapCNPS(row.score) : null,
              count: row.count ?? null,
              displayValues: {
                ...segmentDetails,
                average: this.question?.question_type === 'rating' && row.score !== null ? this.averageRating(row?.score, 'string') : null,
                cnps: this.wrapCNPS(row.score).displayValue,
                count: row.count ? String(row.count) : null,
                // eslint-disable-next-line max-len
                percentages: mapValues(this.wrapPercentages({ count: row.count, scores: segmentDetailsWithoutLabel }), (val) => val.displayValue),
              },
              error: row.count === null,
              reverseStats: this.reverseStats,
              filter: {}, // todo: but why?!
              label: row.label,
              percentages: this.wrapPercentages({ count: row.count, scores: segmentDetailsWithoutLabel }),
              question: this.question.id,
              sortValues: {
                ...segmentDetails,
                count: row.count,
                cnps: this.wrapCNPS(row.score).value,
                average: this.question?.question_type === 'rating' && row.score !== null ? this.averageRating(row.score) : null,
                percentages: this.mapPercentages('value', { percentages: this.wrapPercentages({ count: row.count, scores: segmentDetailsWithoutLabel }) }),
              },
              stats: { ...segmentDetailsWithoutLabel },
            },
          };
        });
        // if compare and filter key matches: Only show rows that the compiledFilter.tags has
        if (this.compareTag && this.rows.length > 0) this.rows = this.onlyReturnUsedKeys(this.rows);
        // Setting the benchmarks column. When compare by tag, always use repeated global and customer averages
        this.benchmarks = this.benchmarkCompany
          .map((row, i) => {
            const multiplicator = this.question.options?.cnps ? 100 : 1;
            return {
              customer: this.wrapBenchmark(row?.score ?? null, multiplicator, 'customer'),
              global: this.wrapBenchmark(this.benchmarkGlobal?.[i]?.score ?? null, multiplicator, 'global'),
            };
          })
          .map((v, i) => Object.assign(v, this.rows[i])) // koppla på alla props från rows
          .sort(rowSort(this.sortCol, sortDesc)) // sortera på samma vis som rows
          .map((o) => pick(o, ['global', 'customer'])); // och filtrera sedan bort alla props från rows

        // After new rows are populated, manually reset thead original (unsorted) rows
        this.$refs.thead?.setOriginals(this.rows, this.benchmarks);

        this.rows.sort(rowSort(this.sortCol, sortDesc));
        this.checkForShowableRows(this.rows);
        this.$emit('update-drag-area');

        return this.rows;
      }
      return [];
    },
    wrapBenchmark(value, multiplicator = 1, benType = 'global') {
      if (this.question.question_type === 'yesno') {
        if (value?.ja || value?.nej) return value;
        if (!this.compareIsDate) return globalTuplets(value, this.reverseStats);
        return { ja: null, nej: null };
      }
      const benTypeScore = benType === 'global' ? this.toqGlobalResponse?.[1]?.score : this.toqResponse?.[3]?.score;
      if (!this.compareIsDate) return (benTypeScore || 0) * multiplicator ?? NO_VALUE;
      return value !== null
        ? value * multiplicator
        : NO_VALUE;
    },
    checkForShowableRows(rows = this.rows) {
      this.hasRegularRows = !!rows.find(({ stats }) => !stats.anonymized && !stats.error);
      const showAnon = !!rows.find(({ stats }) => !!stats.anonymized);
      const showErrored = !!rows.find(({ stats }) => !!stats.error);
      if (showAnon || showErrored) this.hasShowableRows = true;
      else this.hasShowableRows = false;

      if (this.card.metadata.show.rows.anonymizedRows && showAnon
        || this.card.metadata.show.rows.erroredRows && showErrored) this.showingAnyRows = true;
      else this.showingAnyRows = false;

      this.hasAnonRows = showAnon;
      this.hasErroredRows = showErrored;
      return this.hasShowableRows;
    },
    setup() {
      if (!this.card?.hasQuestion?.()) return;
      this.rows = [];
      if (this?.toqResponse?.length > 0 && this?.toqGlobalResponse?.length > 0) this.populateRows();
      this.$emit('update-drag-area');
      if (this.$refs.tableWrapper) {
        this.hasScroll({ currentTarget: this.$refs.tableWrapper.firstChild });
      }
    },
    handleWindowClick() {
      this.focus();
    },
  },
};
</script>
