import { computed } from 'vue';
import { isEmpty, isEqual } from 'lodash-es';
import { isTopicable } from 'API/topics';
import { processText, translateTerm, translateTermOption, translateBenchmark, translateBaseValue } from 'Utils/general';
import { relativeDate, convertDateObjectToAbsoluteDates } from 'Utils/date';
import { format } from 'Utils/dateHelpers';
import { BENCHMARK_ICONS } from 'Utils/benchmarks';
import { KEY_METRIC_ALLOWLIST_LEVELS, KEY_METRIC_GRAPH_TYPES } from 'Utils/graph';
import {
  getPureFilterKey,
  getFilterOperator,
} from 'Utils/filterBox';
import { store } from '@/store';
import gettext from '@/gettext';

const { $gettext, $pgettext } = gettext;

/**
 ** useBadges
 * @param {Proxy} [contextMetadata = { value: { benchmark: undefined, compare: undefined, filter: undefined } }] compiled or board metadata
 * @param {Proxy} [cardMetadata = null] OPTIONAL, same structure as contextMetadata. if you want to check if a badge is a card filter
 * @returns {Object} { allBadges, compareBadges, benchmarkBadges, filterBadges }
 */
export default function useBadges(
  contextMetadata = { value: { benchmark: undefined, compare: undefined, filter: undefined } },
  cardMetadata = null,
) {
  // ? Compare badge
  const compareBadges = computed(() => {
    let acc = [];
    if (contextMetadata.value?.compare) {
      let value = contextMetadata.value.compare.key || contextMetadata.value.compare.tag || $gettext('Datum');
      if (contextMetadata.value.compare.key === 'customerProxy') value = $pgettext('Badge — Compare value', 'Proxies');
      else if (contextMetadata.value.compare.key === 'segment') value = $pgettext('Badge — Compare value', 'Segment');
      acc.push({
        slug: contextMetadata.value?.compare?.tag ? 'compare__tag' : 'compare',
        label: $pgettext('Badge — Compare', 'Jämför'),
        value,
        ...(cardMetadata?.value && cardMetadata.value.compare !== null && { isCardFilter: true }),
      });
    }
    return acc;
  });
  const isKeyMetricCard = computed(() => cardMetadata?.value?.graphType !== undefined
        && Object.values(KEY_METRIC_GRAPH_TYPES).includes(cardMetadata?.value?.graphType?.selected));

  const stepOptions = computed(() => {
    let options = [];
    if (isKeyMetricCard.value) {
      const { any_step, specific } = KEY_METRIC_ALLOWLIST_LEVELS[cardMetadata?.value?.graphType?.selected] || {};
      const customerSteps = store.getters.customerAllStepsOptions.map((option) => {
        if (option?.inactive) return { ...option, sidelabel: $pgettext('Sidelabel — Inactive step', 'Pausat formulär') };
        return option;
      }) || [];
      if (any_step) options = [...options, ...customerSteps];
      else if (specific) options = [...options, ...specific.map(translateTermOption)];
    }
    return options;
  });

  // ? Benchmark badges
  const benchmarkBadges = computed(() => Object.entries(contextMetadata.value?.benchmark ?? {})
    .reduce((acc, [k, v]) => {
      if (!isEmpty(v)) {
        acc.push({
          slug: `benchmark__${k.toLowerCase().split(' ').join('_')}`,
          label: translateTerm(k),
          value: translateBenchmark(k, v),
          icon: BENCHMARK_ICONS[k] || '',
        });
      }
      return acc;
    }, []));

  // ? Filter badges
  const filterBadges = computed(() => {
    const list = [];
    const cardFilter = { ...cardMetadata?.value?.filter };
    Object.entries(contextMetadata.value?.filter ?? {}).forEach(([k, v]) => {
      let badge = {};
      if (!isEmpty(cardFilter) && k in cardFilter && isEqual(cardFilter[k], v)) badge.isCardFilter = true;
      const pureKey = getPureFilterKey(k);

      switch (pureKey) {
        case 'date':
          if (isEmpty(v)) break;
          badge.slug = 'date';
          badge.label = $pgettext('Badgelist', 'Datum');
          if (!isEmpty(cardFilter?.date) && isEqual(cardFilter?.date, v?.unprocessedDate)) badge.isCardFilter = true;
          if (v.type === 'absolute') {
            let dateObj = { ...v };
            dateObj.dateGte = format(new Date(dateObj.dateGte), 'yyyy-MM');
            dateObj.dateLte = format(new Date(dateObj.dateLte), 'yyyy-MM');
            if (v?.unprocessedDate?.span?.allTime) badge.value = [relativeDate(v.unprocessedDate)];
            else if (v?.unprocessedDate?.type === 'relative') badge.value = [`${relativeDate(v.unprocessedDate)} (${v.dateGte} – ${v.dateLte})`];
            else if (dateObj.dateGte === dateObj.dateLte) badge.value = [dateObj.dateGte];
            else badge.value = [$pgettext('Badgelist', 'mellan %{dateGte} och %{dateLte}', dateObj)];
          } else if (v.type === 'relative') {
            const { dateGte, dateLte } = convertDateObjectToAbsoluteDates({ date: v });
            badge.value = [relativeDate(v)];
            badge.overwriteTooltip = `${relativeDate(v)} (${$gettext('För tillfället:')} ${dateGte} – ${dateLte})`;
          }
          list.push(badge);
          break;
        case 'tags':
          if (isEmpty(v)) break;
          Object.entries(v).forEach(([vkey, vval]) => {
            if (cardFilter?.tags?.[vkey]) badge.isCardFilter = true;
            badge.slug = `tags__${getPureFilterKey(vkey).toLowerCase().split(' ').join('_')}`;
            badge.label = getPureFilterKey(vkey);
            badge.value = vval;
            badge.operator = getFilterOperator(vkey);
            list.push({ ...badge });
          });
          break;
        case 'topics':
          if (isEmpty(v) || cardMetadata?.value?.graphType?.selected !== 'FreeText' || !isTopicable(cardMetadata?.value.question)) break;
          badge.slug = 'topics';
          badge.label = $pgettext('Badgelist', 'Textkategori');
          badge.value = store.getters.topics
            .filter((topic) => v.includes(topic.id))
            .map((topic) => topic.name);
          badge.operator = getFilterOperator(k);

          list.push(badge);
          break;
        case 'answers':
          if (isEmpty(v)) break;
          Object.entries(v).forEach(([vkey, vval]) => {
            const answerBadge = store.dispatch('getQuestionById', getPureFilterKey(vkey))
              .then((res) => ({
                slug: `answers__${getPureFilterKey(vkey).toLowerCase().split(' ').join('_')}`,
                label: processText(res.translation.question),
                value: vval.map((ans) => translateBaseValue(ans, res) || ans),
                ...(res?.level && { level: res.level }),
                ...(res?.options?.cnps && { cnps: res.options.cnps }),
                ...(badge?.isCardFilter && { isCardFilter: badge?.isCardFilter }),
                operator: getFilterOperator(vkey),
              })).catch(() => {});

            list.push(answerBadge);
          });
          break;
        case 'segment':
          if (isEmpty(v)) break;
          badge.slug = 'segment';
          badge.label = $pgettext('Badgelist', 'Segment');
          badge.value = store.getters.customerSegments
            .filter((segment) => v.includes(segment.id))
            .map((segment) => segment.name);
          badge.operator = getFilterOperator(k);
          list.push(badge);
          break;
        case 'step':
          if (isEmpty(v)) break;
          badge.slug = 'step';
          badge.label = $pgettext('Badgelist', 'Formulär');
          badge.value = stepOptions.value
            .filter((step) => v.includes(step.value))
            .map((step) => step.label);
          badge.operator = getFilterOperator(k);

          list.push(badge);
          break;
        case 'customerProxies':
          if (isEmpty(v)) break;
          badge.slug = 'customerProxies';
          badge.label = $pgettext('Badgelist', 'Proxy');
          badge.value = store.getters.fetchingProxies
            ? $gettext('Laddar data...')
            : store.getters.customerProxies
              .filter((pval) => v.includes(pval.id))
              .map((pval) => pval.internal_name || pval.name);
          badge.operator = getFilterOperator(k);
          list.push(badge);
          break;
        default:
          break;
      }
    });
    return list;
  });

  // ? All badges
  const allBadges = computed(() => {
    if (
      contextMetadata?.value?.filter === undefined
      && contextMetadata?.value?.benchmark === undefined
      && contextMetadata?.value?.compare === undefined
    ) return [];
    return [
      ...compareBadges.value,
      ...benchmarkBadges.value,
      ...filterBadges.value,
    ];
  });

  return {
    allBadges,
    compareBadges,
    benchmarkBadges,
    filterBadges,
  };
}
