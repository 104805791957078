import { isEmpty, compact, forEach, keys } from 'lodash-es';
import {
  sub, subQuarters, set, getDaysInMonth, getQuarter, lastDayOfMonth, getYear, getMonth,
} from 'date-fns';
import { format, adjustForUTCOffset } from 'Utils/dateHelpers';
import { currentRoute } from 'Utils/urls';
import { getPureFilterKey } from 'Utils/filterBox';
import { store } from '@/store';

export function superagentCacheBust(r) {
  r.url += `?${Date.now().toString(10).substring(2)}`;
  return r;
}

export function superagentSetDashboardOriginPage(r) {
  const currentRouteUrl = currentRoute()?.fullPath || currentRoute()?.path || '';
  if (currentRouteUrl) r.set('TC-Dashboard-Origin-Url', currentRouteUrl);
  return r;
}

export function superagentLanguage(r, useDashboardLanguage) {
  // ? Sets the language received for question titles in `question.translation.question`
  const questionLanguage = store.state.languageSettings?.question_language;
  if (!useDashboardLanguage && questionLanguage) r = r.set('Accept-Language', questionLanguage);
  // else if (Vue.config.language) r = r.set('Accept-Language', Vue.config.language);
  else if (document?.documentElement.lang) r = r.set('Accept-Language', document.documentElement.lang);
  return r;
}

export const filterQueryKeys = {
  dateGt: 'dateGt',
  dateLt: 'dateLt',
  dateGte: 'dateGte',
  dateLte: 'dateLte',
  pageSize: 'page_size',
  customerProxy: 'customer_proxy',
  customerProxies: 'customer_proxies', // Added in [ch1894]
  compareToFilter: 'compare_to_filter', // Added in [ch4203]
  groupBy: 'groupby', // Added in [ch4967]
  avg_gte: 'average_rating__gte',
  avg_lte: 'average_rating__lte',
  cnps_cat: 'cnps_category',
};

const filterQueryModifier = (filterKey) => (filterKey.startsWith('!') ? '__not' : '');

const filterQueryTransforms = {
  unanswered: (value) => [{ submissions_only: !value }],
  tags(tags, toq) {
    if (toq) {
      if (keys(tags).length > 1 || ((keys(tags).length && tags[keys(tags)[0]].length > 1))) {
        let tagSets = { $and: [] };
        forEach(tags, (value, key) => {
          let tagFilters = [];
          value.forEach((val) => {
            tagFilters.push({ tags: { [key]: val } });
          });
          tagSets.$and.push({ $or: [...tagFilters] });
          // for each key $or: [{ tags: { [key]: val } }]
        });
        return tagSets;
      }
      let key = Object.keys(tags)[0];
      let val = tags[Object.keys(tags)[0]][0];
      return { tags: { [key]: val } }; // TODO: flatten the value
    } // not toq
    let filters = [];
    Object.keys(tags).forEach((tag) => {
      const pureTag = getPureFilterKey(tag);
      tags[tag].forEach((value) => {
        filters.push({
          [`tags__${pureTag}${filterQueryModifier(tag)}`]: value,
        });
      });
    });
    return filters;
  },
  answers(answers) {
    let filters = [];
    Object.keys(answers).forEach((questionId) => {
      answers[questionId].forEach((answer) => {
        const pureQId = getPureFilterKey(questionId);
        filters.push({
          [`qa_${pureQId}${filterQueryModifier(questionId)}`]: answer,
        });
      });
    });
    return filters;
  },
  questionIn(questionIds) {
    return [{ question__in: questionIds.join(',') }];
  },
  language(languages) {
    return [{ language: languages.join(',') }];
  },
  date(value, toq) {
    if (value.type === undefined) {
      return [];
    }
    let dateGte = adjustForUTCOffset(new Date());
    let dateLte = adjustForUTCOffset(new Date());
    if (value.type === 'absolute') {
      dateGte = adjustForUTCOffset(new Date(value.dateGte));
      dateLte = lastDayOfMonth(adjustForUTCOffset(new Date(value.dateLte)));
    } else {
      // Offset
      if (value.offset !== null) {
        switch (Object.keys(value.offset)[0]) {
          case 'years':
            dateGte = sub(dateGte, { years: value.offset.years });
            dateLte = sub(dateLte, { years: value.offset.years });
            break;
          case 'quarters':
            dateGte = subQuarters(dateGte, value.offset.quarters);
            dateLte = subQuarters(dateLte, value.offset.quarters);
            break;
          case 'months':
            dateGte = sub(dateGte, { months: value.offset.months });
            dateLte = sub(dateLte, { months: value.offset.months });
            break;
          case 'days':
            dateGte = sub(dateGte, { days: value.offset.days });
            dateLte = sub(dateLte, { days: value.offset.days });
            break;
          default:
            break;
        }
      }
      // Span
      switch (Object.keys(value.span)[0]) {
        case 'allTime':
          dateGte = set(dateGte, { year: 2015, month: 10, date: 1 });
          dateLte = set(dateLte, { year: getYear(dateLte), month: getMonth(dateLte), date: getDaysInMonth(dateLte) });
          break;
        case 'future':
          dateLte = null;
          break;
        case 'years':
          dateGte = set(sub(dateGte, { years: value.span.years - 1 }), { month: 0, date: 1 });
          dateLte = set(sub(dateLte, { years: value.span.years - 1 }), { month: 11, date: 31 });
          break;
        case 'quarters':
          switch (getQuarter(dateGte)) {
            case 1:
              dateGte = set(dateGte, { month: 0, date: 1 });
              dateLte = set(dateLte, { month: 2, date: getDaysInMonth(new Date(getYear(new Date(dateGte)), 2)) });
              break;
            case 2:
              dateGte = set(dateGte, { month: 3, date: 1 });
              dateLte = set(dateLte, { month: 5, date: getDaysInMonth(new Date(getYear(new Date(dateGte)), 5)) });
              break;
            case 3:
              dateGte = set(dateGte, { month: 6, date: 1 });
              dateLte = set(dateLte, { month: 8, date: getDaysInMonth(new Date(getYear(new Date(dateGte)), 8)) });
              break;
            case 4:
              dateGte = set(dateGte, { month: 9, date: 1 });
              dateLte = set(dateLte, { month: 11, date: getDaysInMonth(new Date(getYear(new Date(dateGte)), 11)) });
              break;
            default:
              break;
          }
          break;
        case 'months':
          dateGte = set(sub(dateGte, { months: value.span.months - 1 }), { date: 1 });
          dateLte = set(dateLte, { date: getDaysInMonth(dateLte) });
          break;
        case 'days':
          dateGte = sub(dateGte, { days: value.span.days });
          break;
        default:
          break;
      }
    }
    if (toq) {
      return {
        date:
          { $gte: format(dateGte, 'yyyy-MM-dd'), $lte: format(dateLte, 'yyyy-MM-dd') },
      };
    }
    return [
      { dateGte: format(dateGte, 'yyyy-MM-dd') },
      dateLte ? { dateLte: format(dateLte, 'yyyy-MM-dd') } : {},
    ];
  },
};

function queryKey(key, toq = false) {
  const pureKey = getPureFilterKey(key);
  if (pureKey === 'customerProxies' && !toq) {
    return filterQueryKeys[pureKey] + filterQueryModifier(key);
  }// ? Only because of different filter names between LinegraphAPI and TOQ
  return filterQueryKeys[key] || key;
}

export function queryList(key, value, toq = false) {
  // eslint-disable-next-line no-nested-ternary
  return filterQueryTransforms[key]
    ? filterQueryTransforms[key](value, toq)
    : toq
      ? { [queryKey(key, toq)]: value }
      : [{ [queryKey(key)]: value }];
}

function applyFilter(req, key, value) {
  queryList(key, value).forEach((filter) => {
    req.query(filter);
  });
  return req;
}

export function superagentFilter(filter) {
  return (req) => {
    let r = req;
    if (!filter) return r;

    Object.keys(filter).forEach((key) => {
      if (filter[key] === undefined || filter[key] === null) return;
      r = applyFilter(r, key, filter[key]);
    });

    return r;
  };
}

export function TOQFilter(filter) {
  if (!filter) return null;
  let filterReturn = {};
  const filterKeys = compact(Object.keys(filter)
    .map((key) => {
      if (filter[key] === undefined || filter[key] === null || isEmpty(filter[key])) return null;
      if (key === 'level') return null; // waiting for [3390]
      if (key === 'language') return null; // waiting for [3405]
      if (key === 'answers') return null; // waiting for [3400]
      return queryList(key, filter[key], true); // true indicates it's a TOQ request
    }));

  if (filterKeys.length > 1) { // inte bara date tex
    // filterReturn.$and = []; // from when filter was expected to have $and at the beginning
    filterKeys.forEach((item) => {
      //       if (item.$and) item.$and.forEach((set) => { filterReturn.$and.push(set); });
      filterReturn = { ...filterReturn, ...item };
    });
  } else if (filterKeys[0]) {
    filterReturn = filterKeys[0];
  }
  return filterReturn;
}
