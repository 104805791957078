import { createRouter as _createRouter, createWebHistory, createMemoryHistory } from 'vue-router';
import gaPush from '@/plugins/googleAnalytics';
import intercom from '@/plugins/intercom';
import { captureMessage } from '@/plugins/sentry';
import { accept as customerInviteAccept } from 'API/customerUserInvitation';
import { accept as segmentInviteAccept } from 'API/segmentUserInvitation';
import { currentRoute } from 'Utils/urls';
import { localStorageIsAvailable } from 'Utils/storage';
import { authRouterSetup } from 'Utils/auth';
import { confirmLeaveUnsavedChanges } from 'Components/modal/dialogBoxes';
import { closePane } from 'Components/parts/pane/Pane';
import setSiteTitle from '@/router/siteTitle';
import versioning from '@/router/versioning';
import routes from '@/router/routes';

const versioningTimer = versioning();

export function setupRouterGuards(r, store) {
  r.beforeEach(async (to, from) => {
    // Check if board etc. needs saving before reroute
    if (store.state.modals.hasUnsavedChanges) {
      const answer = await confirmLeaveUnsavedChanges();
      if (answer === true) store.dispatch('setUnsavedChanges', false);
      else return false;
    }
    // Keep lS updated with route param
    if (to?.params?.segmentId) {
      localStorage.setItem('currentSegmentId', to.params.segmentId);
      store.dispatch('setHiddenParams', { segmentId: to.params.segmentId });
    } else if (store.state.router.hiddenParams?.segmentId) localStorage.setItem('currentSegmentId', store.state.router.hiddenParams.segmentId);

    // Set currentRoute global used later on
    currentRoute(to);
    // Redirect if path begins with a hash (ignore hashes later in path)
    if (to?.fullPath?.substr(0, 2) === '/#') {
      const path = to.fullPath.substr(2) || '/';
      // eslint-disable-next-line no-console
      console.log(`[TC] Rerouted from ${to.fullPath} to ${path}`);
      return true;
    } else { // eslint-disable-line no-else-return
      if (to.meta.load) {
        store.dispatch('setLoadState', true);
      }
      if (to.matched.some((m) => m.meta.backHistory) && from.matched.every((m) => m.meta.backHistory !== true)) {
        store.dispatch('setBackHistory', from);
      }
      if (to.name !== from.name) store.dispatch('closeAllModals');
      closePane();
      // TODO: close sidebar dropdown (segment select)
      versioningTimer.runTimersCheck();

      setSiteTitle(to);

      return true;
    }
  });

  r.afterEach((to, from) => {
    // Boot Intercom if not on blacklisted URL or already booted
    if (window?.Intercom && window?.intercomStatus === 0) {
      if (to?.name?.indexOf('print-') > -1) return;
      window.Intercom('boot', { app_id: 'hawr97o7', ...(window.intercomSettings ?? {}) });
      window.intercomStatus = 1; // 1 = booted
      store.dispatch('setupIntercomContext', { segment: store.getters.segment });
    }
    if (window?.Intercom && window?.intercomStatus > 1 && window?.intercomSettings?.segment_id == null) { // ? Run again if segment_id is null
      store.dispatch('setupIntercomContext', { segment: store.getters.segment });
    }
    if (to.meta.intercomTour === 'dashboard') {
      setTimeout(() => {
        // ? Check again if still at same page, 1s after
        if (currentRoute().meta.intercomTour === 'dashboard' && window?.Intercom && window?.intercomStatus > 1) intercom.startDashboardTour();
      }, 1000);
    }
  });
}

export function segmentRouterSetup(r, store) {
  r.beforeEach((to, from, next) => {
    const promise = new Promise((resolve, reject) => {
      if (to.meta.needSegment) {
        if (!store.getters.validSegment) {
          let p;
          if (to.meta.printAuth) {
            store.dispatch('fetchMe').then(() => {
              if (to.params.boardId) {
                p = store.dispatch('fetchSegmentByBoardId', parseInt(to.params?.boardId, 10));
              } else {
                p = store.dispatch('fetchSegment');
              }
              p.then(() => resolve())
                .catch((err) => {
                  store.dispatch('setHiddenParams', { error: err.message });
                  resolve({ name: 'print-error' });
                });
            });
            return;
          }

          store.dispatch('fetchMe')
            .then(async () => {
              const invitation = localStorageIsAvailable && JSON.parse(localStorage.getItem('invite') ?? '{}');
              if (invitation.id && invitation.token) {
                const acceptFunction = invitation.type === 'segmentUser'
                  ? segmentInviteAccept
                  : customerInviteAccept;
                try {
                  const res = await acceptFunction(invitation.id, invitation.token);
                  if (res?.errCode) throw res;
                  else if (localStorageIsAvailable) localStorage.removeItem('invite');
                } catch (err) {
                  console.error('[TC] Had an issue with accepting your invitation.', err?.message ?? err); // eslint-disable-line no-console
                  return Promise.resolve('Invite not necessary to be accepted');
                }
              }
              return Promise.resolve('no invite');
            })
            .then(() => {
              if (to.name === 'board' && to.params.boardId) { // ? ex. /segment/123/board/456 -- 456 maybe has another segmentId, replaced in next .then
                p = store.dispatch('fetchSegmentByBoardId', parseInt(to.params?.boardId, 10));
              } else { // ? ex. /segment/123/feedback OR /customer/settings OR /about
                const currSegParamOrLocal = Number(to.params.segmentId) || store.state.router.hiddenParams.segmentId || localStorageIsAvailable && Number(localStorage.getItem('currentSegmentId')) || null;
                p = store.dispatch('fetchSegment', currSegParamOrLocal);
              }
              p.then((segment) => {
                if (!to.meta.segmentHidden) to.params.segmentId = segment.id;
                store.dispatch('setHiddenParams', { segmentId: segment.id });
                return resolve();
              })
                .catch((err) => {
                  console.log(`[TC] ${err} | Will try with any available segments`); // eslint-disable-line no-console
                  // ? Retry with any available segment before giving up
                  store.dispatch('fetchSegment')
                    .then((segment) => resolve({ name: 'home', params: { segmentId: segment.id } }))
                    .catch((err2) => {
                      store.dispatch('setHiddenParams', { error: `${err} — ${err2?.message || err2}` });
                      resolve({ name: 'login' });
                    });
                });
            })
            .catch((err) => {
              console.error('[TC] Had an issue with accepting your invitation.', err); // eslint-disable-line no-console
              store.dispatch('fetchSegment')
                .then(() => resolve())
                .catch((err2) => {
                  store.dispatch('setHiddenParams', { name: 'login', params: { error: `${err} — ${err2?.message || err2}` } });
                  resolve({ name: 'login' });
                });
            });
          return;
        }
      }

      resolve();
    });
    promise
      .then(async (route) => {
        let routeObject = route;
        if (to.meta.needSegment) {
          let toSegmentId = Number(to?.params?.segmentId) || null;
          const fromSegmentId = Number(from?.params?.segmentId) || null;
          const toFromDiffers = (toSegmentId != null)
            && (fromSegmentId != null)
            && (toSegmentId !== fromSegmentId);
          const toFromNull = toSegmentId == null && fromSegmentId == null;

          const isNavigatingFromCustomerSettings = from.meta.needCustomer && from.params.customerId;
          const isNavigatingFromSettingsToSegment = isNavigatingFromCustomerSettings && toSegmentId !== null;

          if (!to.meta.segmentHidden && toFromNull && store.state.router.hiddenParams.segmentId) {
            return { ...to, params: { ...to?.params, segmentId: store.state.router.hiddenParams.segmentId } };
          }
          if (toFromDiffers || isNavigatingFromSettingsToSegment) {
            // ? Routed to another segment via browser back/forward buttons, need to set segment and block interaction while loading

            if (isNavigatingFromSettingsToSegment) {
              store.dispatch('setHiddenParams', { segmentId: toSegmentId });
            }

            if (to.meta.segmentHidden) {
              store.dispatch('setHiddenParams', { segmentId: toSegmentId ?? fromSegmentId });
              routeObject = { ...to, params: { ...to?.params, ...route } };
              delete routeObject.params.segmentId;
            }
            const loader = document.getElementById('appLoader');
            loader.classList.add('active', 'cthru');
            await store.dispatch('getSegment', toSegmentId);
            loader.classList.remove('active', 'cthru');
            return routeObject;
          }
          if (toSegmentId == null && fromSegmentId) {
            // Fix for routes without segment in URL
            store.dispatch('setHiddenParams', { segmentId: fromSegmentId });
            if (to.meta.segmentHidden) return null;
            return { ...to, params: { ...to?.params, segmentId: fromSegmentId }, ...routeObject };
          }
        }
        return routeObject;
      })
      .then((route) => {
        // let url = to.fullPath;
        // if (to.meta.gaUrl !== undefined) {
        //   url = to.meta.gaUrl;
        //   // ? Likely unnecessary as we can manage this in GA directly
        //   gaPush({ page: `/dashboard${url}` }, '[TC] Pageview');
        // }
        if (route !== null && route !== undefined) {
          next(route);
        } else {
          next();
        }
      });
  });
}

export const routerSettings = {
  routes,
  history: createWebHistory(),
  linkActiveClass: 'active',
  linkExactActiveClass: 'exact-active',
  scrollBehavior(to, from, savedPosition) {
    if (to.fullPath !== from.fullPath) return { top: 0 };
    return savedPosition;
  },
};

function customerSettingsRouterSetup(r, store) {
  const history = [];

  r.beforeEach(async (to, from, next) => {
    const isNavigatingToCustomerSettings = to.meta.needCustomer && to.params.customerId;

    if (isNavigatingToCustomerSettings) {
      // Using deprecated window.event because of edge case when user clicks back to another segment
      // Might need to fix in the future if support is dropped fully
      // https://developer.mozilla.org/en-US/docs/Web/API/Window/event#browser_compatibility
      const clickedBack = window?.event?.type ? window.event.type === 'popstate' : false;
      if (!window?.event) captureMessage('[TC] Window event not available');

      let prevHistory;
      if (clickedBack) {
        prevHistory = history.pop();
        if (prevHistory) {
          store.dispatch('setHiddenParams', { segmentId: prevHistory.segmentId });
          await store.dispatch('getSegment', prevHistory.segmentId);
        }
      }

      // Check if should use segment from hiddenParams/localStorage or from history (if clicked back button)
      let segmentId;
      // If clicked browser back button, segmentId in hidddenParams or ls is not the correct one, so use from history
      if (clickedBack) segmentId = prevHistory.segmentId;
      else segmentId = store.state.router.hiddenParams.segmentId || localStorageIsAvailable && Number(localStorage.getItem('currentSegmentId')) || null;

      // Check if segment belongs to customer
      const segment = await store.dispatch('getSegment', segmentId);
      const segmentBelongsToCustomer = segment.customer.id === Number(to.params.customerId);
      if (!segmentBelongsToCustomer) {
        // If segment does not belong to customer, use first available segment for customer
        const _segment = store.state.me.segments.find((_s) => _s.customer === Number(to.params.customerId));
        // Just set segment to first available segment for customer
        if (_segment?.id) await store.dispatch('getSegment', _segment.id);
      }

      history.push({ segmentId, route: to });

      // Check if user has access to customer
      const c = store.state.me.related_customers.find((_c) => _c.id === Number(to.params.customerId));
      if (!c) {
        console.error('[TC] User does not have access to this customer'); // eslint-disable-line no-console
        // If user is not authorized to access customer, use first available customer from store
        const firstCustomer = store.state.me.related_customers[0];
        next({ ...to, params: { ...to?.params, customerId: firstCustomer.id } });
      } else next();
    } else next();
  });
}

export default function createRouter(store, app) {
  const router = _createRouter(routerSettings);

  setupRouterGuards(router, store);
  authRouterSetup(router, app); // ? pass all routings thru auth check and direct accordingly
  segmentRouterSetup(router, store);
  customerSettingsRouterSetup(router, store);

  return router;
}

export const createTestRouter = () => _createRouter({ routes, history: createMemoryHistory() });
