<template>
  <div class="tc-card-takeover">
    <div class="tc-card">
      <div class="tc-card-header p-card">
        <h3
          @click="showLocalhost = !showLocalhost"
        >
          {{ $gettext('Trustcruit Dashboard') }}
        </h3>
      </div>
      <div class="tc-card-body p-card">
        <template v-if="routeErrorMessage">
          <div
            class="alert alert-error"
            role="alert"
          >
            <i class="zmdi zmdi-alert-triangle pr-1" /> {{ routeErrorMessage }}
            <i
              class="zmdi zmdi-close cursor-pointer close pl-1"
              @click="routeErrorMessage = ''"
            />
          </div>
          <hr>
        </template>
        <form
          class="mb-5"
          @submit.prevent="login"
        >
          <div class="form-group">
            <label
              class="control-label"
            >{{ $gettext('E-mailadress') }}</label>
            <input
              id="email"
              ref="email"
              v-model="emailAddress"
              type="email"
              :disabled="loading"
              :class="{ 'shake': isShaking }"
              class="form-control form-control-filled animated"
              autocomplete="email"
              autofocus
              tabindex="1"
              data-private="lipsum"
              placeholder="your@email.here"
            >
            <div
              v-if="error.email && error.email[0]"
              class="help-block validation-message is-invalid"
            >
              {{ error.email[0] }}
            </div>
          </div>
          <div class="form-group mt-3">
            <transition name="fade">
              <div
                v-if="errorMessage"
                class="alert alert-error mb-3"
                role="alert"
              >
                <i class="zmdi zmdi-alert-triangle pr-1" /> {{ errorMessage }}<br>
              </div>
            </transition>
            <button
              id="sendOTP"
              type="submit"
              class="btn btn-block py-1"
              :disabled="!!gdprUrl || loading"
              tabindex="2"
            >
              <span v-if="!loading">{{ $gettext('Logga in') }}</span>
              <span v-else>
                <i class="zmdi zmdi-spinner zmdi-hc-spin" /><span>{{ $gettext('Loggar in') }}</span>
              </span>
            </button>
          </div>
        </form>
        <form
          v-show="isDev && showLocalhost"
          class="login-form"
          @submit.prevent="loginLocalhost"
        >
          <hr
            class="tc-hr-text subtle-text mt-4"
            data-text="OR Localhost login"
            @click="showLocalhost = !showLocalhost"
          >
          <div class="form-group">
            <label
              class="control-label"
            >{{ $gettext('Användarnamn') }}</label>
            <input
              id="username"
              ref="username"
              v-model="username"
              type="text"
              :class="{ 'shake': isShaking }"
              class="form-control form-control-filled animated"
              autocomplete="username"
              data-private="lipsum"
            >
            <div
              v-if="error.username && error.username[0]"
              class="help-block validation-message is-invalid"
            >
              {{ error.username[0] }}
            </div>
          </div>
          <div class="form-group">
            <label
              class="control-label"
            >{{ $gettext('Lösenord') }}</label>
            <input
              id="password"
              v-model="password"
              type="password"
              class="form-control form-control-filled animated"
              :class="{ 'shake': isShaking }"
              autocomplete="current-password"
              data-private="redact"
            >
            <div
              v-if="error.password && error.password[0]"
              class="help-block validation-message is-invalid"
            >
              {{ error.password[0] }}
            </div>
          </div>
          <transition name="fade">
            <div v-show="!!gdprUrl || !!errorMessage">
              <template v-if="!!gdprUrl">
                <div
                  class="alert alert-info mb-2"
                  role="alert"
                >
                  <p><i class="zmdi zmdi-alert-triangle pr-1" /> {{ errorMessage }}</p>
                </div>
                <a
                  ref="gdprButton"
                  tabindex
                  class="btn btn-block mb-4"
                  :href="gdprUrl"
                >{{ $gettext('Läs &amp; acceptera villkor') }}</a>
              </template>
              <div
                v-else
                class="alert alert-error"
                role="alert"
              >
                <i class="zmdi zmdi-alert-triangle pr-1" /> {{ errorMessage }}<br>
                <!-- <span v-if="error.detail">{{ error.detail }}</span> -->
                <!-- Not showing "detail" yet bc. the returned message is a tiny bit confusing -->
              </div>
            </div>
          </transition>
          <div class="form-group">
            <button
              id="login"
              type="submit"
              class="btn btn-block"
              :disabled="!!gdprUrl"
            >
              {{ $gettext('Logga in (localhost only)') }}
            </button>
          </div>
        </form>
        <login-footer
          class="bleed"
          :login-link="false"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import gaPush from '@/plugins/googleAnalytics';
import loginAPI from 'API/login';
import { localStorageIsAvailable } from 'Utils/storage';
import { loginUserPW } from 'Utils/auth';
import { getToken, refreshToken } from 'Utils/authHelpers';
import LoginFooter from '../parts/LoginFooter';

const { AUTH_ERROR, AUTH_TYPE } = loginAPI;

export default {
  name: 'LoginPage',
  components: {
    LoginFooter,
  },
  props: [
    'token',
    'email',
  ],
  data() {
    return {
      loading: false,
      emailAddress: '',
      username: '',
      password: '',
      error: {},
      errorMessage: '',
      routeErrorMessage: '',
      isShaking: false,
      showLocalhost: false,
      focused: '',
      gdprUrl: '',
    };
  },
  computed: {
    ...mapState({
      hiddenParams: (state) => state.router.hiddenParams,
    }),
    nextRoute() {
      return this.hiddenParams?.next !== undefined ? this.hiddenParams?.next : '/';
    },
    isDev() {
      return process.env.VITE_ENV === 'development';
    },
    baseUrl() {
      return process.env.BASE_URL;
    },
  },
  mounted() {
    this.$nextTick(() => {
      if (this.$route?.query.error === AUTH_ERROR.UNABLE_TO_LOGIN) {
        gaPush({
          event: '[TC] Login Error',
          eventAction: 'Login Error',
          eventCategory: 'Dashboard',
          eventLabel: `Tried logging in with SSO ${AUTH_ERROR.UNABLE_TO_LOGIN}`,
          hitType: 'event',
        });
        this.routeErrorMessage = this.$pgettext('Error - UNABLE_TO_LOGIN', 'Inloggning misslyckades');
      } else if (this.hiddenParams.error) this.routeErrorMessage = this.hiddenParams.error;

      if (this.hiddenParams.email) this.emailAddress = this.hiddenParams.email;

      const gottenToken = getToken();
      if (gottenToken.refresca !== null && gottenToken.needsRefresh && this.routeErrorMessage === '') {
        refreshToken(gottenToken.refresca)
          .then(() => {
            this.$router.push(this.nextRoute).catch((err) => { this.routeErrorMessage = err; });
          })
          .catch((err) => { this.routeErrorMessage = err; });
      }
    });
  },
  methods: {
    ...mapActions([
      'setHiddenParams',
    ]),
    async login() {
      if (localStorage?.token) localStorage.removeItem('token');
      this.routeErrorMessage = '';
      this.error = {};

      if (this.emailAddress !== '') {
        this.loading = true;

        // Set emailAddress to use on /new-account or /otp
        if (localStorageIsAvailable) localStorage.setItem('emailAddress', this.emailAddress);

        try {
          const res = await loginAPI.requestLogin(this.emailAddress);

          if (res.auth_type === AUTH_TYPE.OTP) {
            if (localStorageIsAvailable) localStorage.setItem('gdprAccepted', res.gdpr_accepted);
            this.setHiddenParams({ email: this.emailAddress, next: this.nextRoute });
            this.$router.push({ name: 'otp' });
          }
          if (res.auth_type === AUTH_TYPE.SSO) window.location.href = res.redirect_url; // redirect to SSO provider
        } catch (err) {
          switch (err.message) {
            case AUTH_ERROR.NO_GDPR_FOR_SSO:
              this.$router.push({ name: 'accept-terms' });
              break;
            default:
              // Error is not an expected auth error
              this.showErrorMessage = true;
              this.error = err.response?.body?.error || err.response?.body || err;
              this.errorMessage = this.$gettext('Inloggning misslyckades');
              this.isShaking = true;

              setTimeout(() => {
                this.isShaking = false;
              }, 500);
          }
        } finally {
          this.loading = false;
        }
      } else {
        this.error = { email: [this.$gettext('Du måste ange en e-mailadress')] };
      }
    },
    loginLocalhost() {
      this.routeErrorMessage = '';
      this.error = {};

      loginUserPW(this.username, this.password).then(
        () => {
          this.$router.push(this.nextRoute).catch((err) => { // ? Causes a warning in console because of a benevolent router guard
            this.errorMessage = this.$gettext('Inloggning misslyckades');
            console.error('[TC] Login routing error', err); // eslint-disable-line no-console
          });
        },
        (err) => {
          this.error = err;
          if (err.gdpr_url !== undefined) {
            this.errorMessage = this.$gettext('Du måste acceptera våra villkor innan du kan logga in.');
            this.gdprUrl = err.gdpr_url[0];
            setTimeout(() => this.$refs.gdprButton?.focus(), 250);
          } else {
            this.errorMessage = this.$gettext('Inloggning misslyckades');
            this.gdprUrl = '';
            setTimeout(() => this.$refs.username?.focus(), 250);
          }
          this.isShaking = true;

          setTimeout(() => {
            this.isShaking = false;
          }, 500);
        },
      );
    },
  },
};
</script>
