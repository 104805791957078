import * as Sentry from '@sentry/vue';
// import { BrowserTracing } from '@sentry/tracing';

export function init(app) {
  Sentry.init({
    app,
    release: `tc-dash@${JSON.parse(unescape(process.env.VUE_APP_VERSION))}`,
    dsn: 'https://5a792f57f1be438b8c1ff302bac8af78@sentry.io/1496153',

    // ? Replays tab in sentry
    // This sets the sample rate to be 50%. You may want this to be 100% while in development and sample at a lower rate in production
    replaysSessionSampleRate: process.env.NODE_ENV === 'production' ? 0.5 : 0.1, // 0.5,
    // If the entire session is not sampled, use the below sample rate to sample sessions when an error occurs.
    replaysOnErrorSampleRate: 1.0,
    integrations: [Sentry.replayIntegration({
      blockAllMedia: false,
      maskAllText: false,
      maskAllInputs: true,
      mask: ['.sentry-mask', '[data-sentry-mask]', '[data-private]'],
      ignore: ['.sentry-ignore', '[data-sentry-ignore]', '[data-private="redact"]'],
    })],

    // ? If we want to use the performance tab in sentry enable this below and allow headers in BE
    // integrations: [
    //   new BrowserTracing({
    //     routingInstrumentation: Sentry.vueRouterInstrumentation(router),
    //     tracingOrigins: ['api.trustcruit.com'], // ['localhost:8000', 'api.trustcruit.com', /^\//]
    //   }),
    // ],
    // // Set tracesSampleRate to 1.0 to capture 100% of transactions for performance monitoring.
    // // We recommend adjusting this value in production
    // tracesSampleRate: 1.0,
  });
}

export function identify(user) {
  if (Sentry?.getCurrentScope) {
    Sentry.getCurrentScope().setUser({
      id: user.id,
      username: user.is_staff ? `${user.username || user.first_name} (staff)` : null,
      // name: `${user.first_name} ${user.last_name}`,
      // email: user.email,
    });
  }
}

export function captureException(...args) {
  if (!['production', 'test', 'staging'].includes(process.env.NODE_ENV)) {
    /* eslint no-console: ["error", { allow: ["log", "warn", "error"] }] */
    console.log('[TC] Sentry captureException', args);
  } else if (Sentry && typeof Sentry.captureException === 'function') {
    Sentry.captureException(args);
  }
}

export function captureMessage(...args) {
  // if (process.env.NODE_ENV !== 'production' && process.env.NODE_ENV !== 'test') {
  if (!['production', 'test', 'staging'].includes(process.env.NODE_ENV)) {
    /* eslint no-console: ["error", { allow: ["log", "warn", "error"] }] */
    console.log('[TC] Sentry captureMessage', args);
  } else if (Sentry && typeof Sentry.captureMessage === 'function') {
    Sentry.captureMessage(args);
  }
}

export function setupSentryContext(segment = {}) {
  let context = {
    segmentId: segment.id,
    segmentName: segment.name,
    customerId: segment.customer.id,
    customerName: segment.customer.name,
  };

  if (Sentry?.getCurrentScope) {
    Object.entries(context).forEach(([key, value]) => {
      Sentry.getCurrentScope().setTag(key, value);
    });
  }
}

export default {
  init,
  identify,
  captureException,
  setupSentryContext,
};
