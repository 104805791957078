<template>
  <div class="tc-performance-score">
    <CardDiv
      v-tc-loader-bar="loading"
      :loading="loading || null"
    >
      <template #body>
        <div
          class="performance-score-wrapper"
          data-testid="cardBody"
        >
          <div
            v-if="scores.length !== 0"
            class="tc-list-group-wrapper p-card"
            data-testid="tableVisible"
          >
            <performance-score-table
              :card="card"
              :comparable-option="comparableOption"
              :items="scores"
              :is-print-view-route="isPrintViewRoute"
              @update-drag-area="updateLayout"
            />
          </div>
          <div
            v-else
            data-testid="tableHidden"
            class="tc-card-message-wrapper p-card"
          >
            <div
              v-if="!loading"
              class="tc-card-message-overlay tc-card-message-overlay-tight"
            >
              <p class="mb-1">
                {{ $gettext('Kunde inte sammanställa ranking med nuvarande filter.') }}
              </p>
              <p>
                {{ $gettext('Prova att byta filter.') }}
              </p>
            </div>
            <div class="tc-card-message-underlay">
              <performance-score-table
                :card="card"
                :comparable-option="comparableOption"
                :items="placeholderScores"
                :loading="loading"
                :skeleton-loader="true"
              />
            </div>
          </div>
          <template v-if="!isPrintViewRoute">
            <div
              class="tc-sw-help-btn pr-2"
              :class="{ 'py-2': !showHelp, 'pt-2': showHelp }"
            >
              <button
                class="help-button btn btn-link ml-3"
                @click="showHelp = !showHelp"
              >
                {{ $gettext('Hjälp att förstå') }} <i class="zmdi zmdi-help" />
              </button>
            </div>
            <div
              v-if="showHelp"
              class="tc-sw-help p-card"
            >
              <div class="row">
                <div class="col-xs-12">
                  <small
                    class="subtle-text small-header"
                  > {{ $gettext('Prioritetsskala:') }} </small>
                  <div class="row">
                    <ol class="plain-list small-text col-xs-12 col-md-6">
                      <li class="pb-1">
                        <performance-score-indicator
                          class="tc-sw-indicator-wrapper mr-1"
                          :score="99"
                          :comparable-option="comparableOption"
                          :show-compare="true"
                        />
                        <span>{{ $gettext('Bra jobbat, bland de bästa') }}</span>
                      </li>
                      <li class="pb-1">
                        <performance-score-indicator
                          class="tc-sw-indicator-wrapper mr-1"
                          :score="70"
                          :comparable-option="comparableOption"
                          :show-compare="true"
                        />
                        <span>{{ $gettext('Snart bland de bästa') }}</span>
                      </li>
                    </ol>
                    <ol class="plain-list small-text col-xs-12 col-md-6">
                      <li class="pb-1">
                        <performance-score-indicator
                          class="tc-sw-indicator-wrapper mr-1"
                          :score="3"
                          :comparable-option="comparableOption"
                          :show-compare="true"
                        />
                        <span>{{ $gettext('Detta bör åtgärdas') }}</span>
                      </li>
                      <li class="pb-1">
                        <performance-score-indicator
                          class="tc-sw-indicator-wrapper mr-1"
                          :score="30"
                          :comparable-option="comparableOption"
                          :show-compare="true"
                        />
                        <span>{{ $gettext('Håll koll på denna fråga') }}</span>
                      </li>
                    </ol>
                  </div>
                </div>
                <div class="col-lg-9 col-xxl-8 col-xxxl-7">
                  <p class="help-block">
                    <!-- eslint-disable-next-line max-len -->
                    {{ $gettext('En röd stapel indikerar att segmentet/proxyn är sämre än jämförelsetalet medan en grön stapel innebär ett värde som är bättre än det du jämför med.') }}
                    <br>
                    {{ $gettext('Prestationen baseras på snittet av alla benchmarkfrågor.') }}
                  </p>
                </div>
              </div>
            </div>
          </template>
        </div>
      </template>
    </CardDiv>
  </div>
</template>

<script>
import { pickBy, isArray, isEmpty, debounce, isEqual } from 'lodash-es'; // eslint-disable-line object-curly-newline
import { mapGetters } from 'vuex';
import { requestPerformance } from 'API/analysis';
import eventBus from 'Utils/eventBus';
import { equalMetadata } from 'Utils/card';
import { equalLiteral } from 'Utils/general';
import { KEY_METRIC_GRAPH_TYPES } from 'Utils/graph';
import {
  getPrefixedFilterKey,
  getFilterOperator,
} from 'Utils/filterBox';
import CardDiv from 'Components/parts/CardDiv';
import PerformanceScoreTable from 'Components/parts/graph/PerformanceScoreTable';
import PerformanceScoreIndicator from 'Components/parts/graph/PerformanceScoreIndicator';

export default {
  name: 'PerformanceScore',
  components: {
    PerformanceScoreTable,
    PerformanceScoreIndicator,
    CardDiv,
  },
  props: {
    card: Object,
    compiledFilter: Object,
    compiledBenchmark: Object,
    contextBenchmark: Object, // Unused here
    isInBoard: {
      type: Boolean,
      default: false,
    },
    previewMode: {
      type: Boolean,
      default: false,
    },
    hydrateProps: { // ? Fill in comp from outside
      type: [Object, null],
      default: null,
    },
  },
  emits: ['loading', 'update-drag-area', 'hydrate-props'],
  data() {
    return {
      disableSomeFilters: true, // ? This is because we didn’t want to release those filters after dicussing
      showHelp: false,
      abortToken: Math.random().toString(10).substring(2),
      loading: true,
      performanceResponse: [],
      scores: [],
      placeholderScores: [
        { title: 'All data', score: 89 },
        { title: 'Kombinerad data', score: 70 },
        { title: 'Framdragen data', score: 61 },
        { title: 'Inte all data', score: 40 },
        { title: 'Nästan all data', score: 20 },
        { title: 'Avklarad data', score: 0 },
      ],
      getPerformanceScore: () => {},
    };
  },
  computed: {
    ...mapGetters([
      'customerId',
      'customerName',
      'segmentId',
      'segmentFilter',
      'segment',
      'modalCard',
    ]),
    isPrintViewRoute() {
      return this.$route.name?.indexOf('print-') > -1;
    },
    currentFilter() {
      let stepFilterKey = null;
      if (this.compiledFilter?.step) stepFilterKey = 'step';
      else if (this.compiledFilter?.['!step']) stepFilterKey = '!step';

      const question = stepFilterKey ? { [stepFilterKey]: this.compiledFilter?.[stepFilterKey] } : {};

      let segmentFilterKey = null;
      if (this.compiledFilter?.segment) segmentFilterKey = 'segment';
      else if (this.compiledFilter?.['!segment']) segmentFilterKey = '!segment';

      const segmentFilterOperator = getFilterOperator(segmentFilterKey || '');
      const segmentIdKey = getPrefixedFilterKey('segment_id', segmentFilterOperator);

      return {
        segment: {
          ...(segmentFilterKey && { [segmentIdKey]: this.compiledFilter?.[segmentFilterKey] }),
        },
        customer_proxy: { customer_id: this.customerId },
        question,
        ...pickBy(this.segmentFilter, (val, key) => key !== 'benchmark'),
        date: this.compiledFilter?.date || this.segmentFilter?.date || {},
      };
    },
    localCard() { return this.previewMode ? this.modalCard : this.card; },
    comparableOption() {
      if (this.compiledBenchmark?.general?.includes('company')) return [{ label: this.customerName, value: this.customerName, icon: 'zmdi-city zmdi-hc-fw' }];
      return [{ value: 'global', label: this.hasBenchmarkFilter ? this.$pgettext('Sector', 'Benchmark') : this.$pgettext('Sector', 'Global'), icon: 'zmdi-globe' }];
    },
    hasBenchmarkFilter() {
      if (isEmpty(this.compiledBenchmark) || this.compiledBenchmark?.general?.includes('global')) return false;
      return Object.values(this.benchmarkFilter)
        .reduce((acc, bench) => (bench?.length >= 0 ? acc + bench.length : acc), 0) > 0;
    },
    benchmarkFilter() {
      /* eslint-disable max-len */
      const benchmarkSize = this.modalCard?.metadata?.benchmark?.size || this.card?.metadata?.benchmark?.size || [];
      const benchmarkSector = this.modalCard?.metadata?.benchmark?.sector || this.card?.metadata?.benchmark?.sector || [];
      const benchmarkLocation = this.modalCard?.metadata?.benchmark?.location || this.card?.metadata?.benchmark?.location || [];
      const benchmarkIndustry = this.modalCard?.metadata?.benchmark?.industry || this.card?.metadata?.benchmark?.industry || [];

      return {
        ...(!isEmpty(benchmarkSize) && { size: benchmarkSize }
          || !isEmpty(this.contextBenchmark?.size) && { size: this.contextBenchmark.size }),
        ...(!isEmpty(benchmarkSector) && { sector: benchmarkSector }
          || !isEmpty(this.contextBenchmark?.sector) && { sector: this.contextBenchmark.sector }),
        ...(!isEmpty(benchmarkLocation) && { country: benchmarkLocation }
          || !isEmpty(this.contextBenchmark?.location) && { country: this.contextBenchmark.location }),
        ...(!isEmpty(benchmarkIndustry) && { industry: benchmarkIndustry }
          || !isEmpty(this.contextBenchmark?.industry) && { industry: this.contextBenchmark.industry }),
      }; /* eslint-enable max-len */
    },
    compareBy() {
      if (this.compiledBenchmark?.general?.includes('company')) return { customer_proxy: { customer_id: this.customerId } };
      if (this.hasBenchmarkFilter) return { customer_proxy: this.benchmarkFilter };
      return {};
    },
    compareToFilter() {
      return { ...this.compareBy,
        date: this.currentFilter.date || {} };
    },
  },
  watch: {
    'card.metadata': {
      deep: true,
      immediate: true,
      handler(newVal, oldVal) {
        if (!equalMetadata(newVal, oldVal)) {
          this.setup();
          // this.getPerformanceScore();
        }
      },
    },
    compareBy(newVal, oldVal) { if (!isEqual(newVal, oldVal)) this.setup(); },
    loading(newVal) { if (!newVal && this.isInBoard) this.$emit('loading', newVal); }, // ? Triggers fullyLoadedCards so Sejda knows when to print
    segmentId(newVal, oldVal) { if (newVal !== oldVal) this.setup(); },
    compiledFilter(newVal, oldVal) {
      if (!isEmpty(newVal) && !equalLiteral(newVal, oldVal)) {
        this.loading = true;
        this.getPerformanceScore();
      }
    },
  },
  beforeUnmount() {
    if (typeof this.getPerformanceScore?.cancel === 'function') this.getPerformanceScore.cancel(); // ? Cancel all pending debounced requests
    eventBus.$emit(`abortRequest:${this.abortToken}`);
  },
  beforeMount() {
    // ? Debounced fn needs to be in data and not in methods to support more instances of this component
    this.getPerformanceScore = debounce(async (filter = this.currentFilter, compareToFilter = this.compareToFilter) => {
      let response;
      this.loading = true;
      const query = { filter, compareToFilter, groupBy: ['segment'] };
      const useSavedResponse = isEqual(this.hydrateProps?.[KEY_METRIC_GRAPH_TYPES.PerformanceScore]?.query, query)
        && !isEmpty(this.hydrateProps?.[KEY_METRIC_GRAPH_TYPES.PerformanceScore]?.performanceResponse);

      if (useSavedResponse) { // eslint-disable-line max-len
        response = this.hydrateProps[KEY_METRIC_GRAPH_TYPES.PerformanceScore].performanceResponse;
      } else {
        response = await requestPerformance(query, this.abortToken);
      }
      this.performanceResponse = isArray(response) ? response : [];

      if (this.isInBoard) {
        this.$emit('hydrate-props', { [KEY_METRIC_GRAPH_TYPES.PerformanceScore]: {
          performanceResponse: this.performanceResponse,
          query,
        } });
      }

      this.scores = this.performanceResponse
        .map((resp, i) => ({
          id: resp.segment_id ?? NaN,
          title: resp.segmentname ?? '',
          score: resp.trustcruit_score ?? 50,
          comment: resp.comment ?? '',
        }))
        .sort((a, b) => b.score - a.score);

      this.loading = false;
    }, 100);
  },
  mounted() {
    this.setup();
  },
  methods: {
    updateLayout() { // ? emits when transiton is done
      this.$emit('update-drag-area');
    },
    setup() { this.getPerformanceScore(); },
  },
};
</script>
