import { isArray, isEmpty, isEqual, isObject, xor, flattenDeep } from 'lodash-es';
import { klona } from 'klona';

/*
  * filterBox
  ? filter is an object like this:
  @example
  {
    "date": {
      "span": { "months": 6 },
      "type": "relative",
      "offset": null
    },
    "tags": {
      "Job ad title": ["Admininstrativ assistent, "Lärare till grundsärskolan"],
      "Gender": ["Kvinna", "Man", "Unknown"]
    },
    "level": "step2",
    "answers": {
      "405": ["0", "1", "2", "3", "4", "5", "6", "8", "9", "10", "7"]
    },
    "language": ["sv-se", "en-us"],
    "customerProxies": [58, 59],
    "segment": [{
      "label": "All data",
      "value": 1652
    }],
    "benchmark": {
      "location": ["DE"],
      "industry": ["Blue collar","White collar"]
    }
  }
*/

export const FILTER_OPERATOR_EXCLUDE = 'exclude';
export const FILTER_OPERATOR_INCLUDE = 'include';

// ! If you add a duplicate between these two arrays, make sure to look over so it works in updateFilterBoxRow()
export const FILTERBOX_ALLOWLISTED_FILTER_TYPES = ['date', 'tags', 'answers', 'segment', 'customerProxies', 'topics']; // ? Except for 'benchmark', that is handled separately
export const FILTER_TYPES_THAT_HAVE_KEYS = ['tags', 'answers'];
export const FILTER_TYPES_THAT_ARE_SINGLE_SELECTABLE = ['date'];
export const FILTER_TYPES_THAT_ARE_SET_DIRECTLY = ['sector', 'industry', 'location', 'size', 'compare'];

/**
 ** usesFilterTypeKey
 *
 * @param {String} filterType
 *
 * @returns {Boolean} - is filterType in FILTER_TYPES_THAT_HAVE_KEYS or not
 */
export function usesFilterTypeKey(filterType) {
  return FILTER_TYPES_THAT_HAVE_KEYS.includes(filterType);
}

/**
 ** isSingleSelectable
 *
 * @param {String} filterType
 *
 * @returns {Boolean} - is filterType in FILTER_TYPES_THAT_ARE_SINGLE_SELECTABLE or not
 */
export function isSingleSelectable(filterType) {
  return FILTER_TYPES_THAT_ARE_SINGLE_SELECTABLE.includes(filterType);
}

/**
 ** mergeSimpleMetadata
 * @param {Object} currentMetadata
 * @param {Array} options
 * @returns {Object} - merged metadata
 */
export function mergeSimpleMetadata(currentMetadata, options) {
  return options.reduce((acc, option) => {
    if (acc[option.group]) { // ? Exists
      if (!acc[option.group].includes(option.value)) { // ? Not in array
        acc[option.group] = [...acc[option.group], option.value];
      } else { // ? Already in array
        // TODO: Fix, this is not working
        if (!option.selected) acc[option.group] = acc[option.group].filter((val) => val !== option.value); // eslint-disable-line no-lonely-if
        // else acc[option.group] = acc[option.group].filter((val) => val === option.value);
      }
    } else { // ? Doesn’t exist yet
      acc[option.group] = [option.value];
    }
    return acc;
  }, klona(currentMetadata ?? {}));
}

/**
  ** isContextEqual - Compares two objects with a context key
  * @param {Object} objVal - Object to compare
  * @param {Object} othVal - Object to compare
  * @param {String} context - Key to compare
  * @returns {Boolean} - Are the objects equal in the context key
  */
export const isContextEqual = (objVal, othVal, context = '') => {
  const objContext = objVal?.[context] ?? {};
  const othContext = othVal?.[context] ?? {};
  const objKeys = Object.keys(objContext) ?? [];
  const othKeys = Object.keys(othContext) ?? [];
  const allKeys = [...new Set([...objKeys, ...othKeys])];

  if (context === 'filter') { // ? Standardize the new Migration to always have these keys including `filter.date`
    objContext.tags = objContext.tags ?? {};
    objContext.answers = objContext.answers ?? {};
  }

  return allKeys.every((key) => {
    const bothHasKey = objKeys.includes(key) && othKeys.includes(key);
    const bothContextsEmptyForKey = bothHasKey && isEmpty(objContext[key]) && isEmpty(othContext[key]);

    if (bothContextsEmptyForKey) return true;
    if (!bothHasKey) return false;

    if (key === 'date') return isEqual(objContext[key], othContext[key]);
    if (isObject(objContext[key]) && isObject(othContext[key])) {
      // First, check if the number of keys are the same
      if (Object.keys(objContext[key]).length !== Object.keys(othContext[key]).length) {
        const diffingKeys = xor(Object.keys(objContext[key]), Object.keys(othContext[key]));
        const diffingKeysHasEmptyValues = diffingKeys
          .every((diffingKey) => isEmpty(objContext[key][diffingKey])
            && (isEmpty(othContext[key][diffingKey]) || !othContext[key][diffingKey]));

        if (diffingKeysHasEmptyValues) return true; // ? Consider `{ tags: {} }` & `{ tags: { a: [] } }` the same
      }

      // Check for diffing keys, which may happen if switching filter operator
      const othKeysFlattened = flattenDeep(Object.keys(othContext[key]));
      const objKeysFlattened = flattenDeep(Object.keys(objContext[key]));

      const othValuesFlattened = flattenDeep(Object.values(othContext[key]));
      const objValuesFlattened = flattenDeep(Object.values(objContext[key]));
      // Compare values
      return (
        xor(othKeysFlattened, objKeysFlattened).length === 0
        && xor(objValuesFlattened, othValuesFlattened).length === 0
      );
    }
    return true;
  });
};

/**
  ** isEqualCustomizerChill - Customizer for lodash isEqualWith
  * @param {Object} objVal - Object to compare
  * @param {Object} othVal - Object to compare
  * @returns {Boolean} - Are the objects equal
  */
export const isEqualCustomizerChill = (objVal, othVal) => {
  if (objVal === null && isArray(othVal) && isEmpty(othVal)) return true; // ? Consider obj`{ compare: null }` & oth`{ compare: [] }` the same
  const filterEqual = othVal?.filter && objVal?.filter ? isContextEqual(objVal, othVal, 'filter') : true;
  const benchmarkEqual = othVal?.benchmark && objVal?.benchmark ? isContextEqual(objVal, othVal, 'benchmark') : true;
  const compareEqual = isEqual(othVal?.compare, objVal?.compare);
  if (!filterEqual || !benchmarkEqual || !compareEqual) return false;
  if (filterEqual && benchmarkEqual && compareEqual) return true;
  return undefined; // ? Other values are handled by default isEqual
};

function compareSetValues(oldSet, newSet) {
  if (!isArray(oldSet) || !isArray(newSet)) return null;
  return newSet.every((newValue) => oldSet.some((oldValue) => oldValue === newValue));
}

// Remove these if they are still commented out in >1 year
// export function omitEmptyFilterBoxRows(filters) {
//   return Object.entries(filters)
//     .reduce((acc, [filterType, entry]) => {
//       if (usesFilterTypeKey(filterType)) { // ? ex. 'tags'
//         acc[filterType] = Object.entries(entry).reduce((acc2, [key, value]) => {
//           if (value.length !== 0) acc2[key] = value;
//           return acc2;
//         }, {});
//         if (isEmpty(acc[filterType])) delete acc[filterType];
//       } else if (!isEmpty(entry)) {
//         acc[filterType] = entry; // ? ex. 'customerProxies'
//       }
//       return acc;
//     }, {});
// }

// export function removeFilterBoxRow(filters, boxRow) {
//   const newFilter = { ...filters };
//   forOwn(newFilter, (filterTypeEntry, filterType) => {
//     if (filterType === boxRow.type || filterType === boxRow.key) { // ? type matches for filter, key matches for benchmark. Sry... I know. Officially a flustercluck now
//       if (usesFilterTypeKey(boxRow.type)) { // ? ex. 'tags'
//         delete newFilter[filterType][boxRow.key]; // ? ex. 'Recruiter name'
//       } else {
//         delete newFilter[filterType];
//       }
//     }
//   });
//   return newFilter;
// }

const transformWithOperator = (key, operator) => {
  if (operator === FILTER_OPERATOR_EXCLUDE && !key.startsWith('!')) return `!${key}`;
  if (operator === FILTER_OPERATOR_INCLUDE && key.startsWith('!')) return key.slice(1);
  return key;
};

export function mergeFilterBoxRow(filters, boxRow) {
  const newFilter = { ...filters };
  const { type, key, value } = boxRow; // ? filter: type is top-level, benchmark: key is top-level
  const kkey = transformWithOperator(key, boxRow.operator);

  if (type === null && kkey === null) return newFilter;
  const union = (a, b) => [...new Set([...a, ...b])];
  if (isEmpty(newFilter) || !(type in newFilter || kkey in newFilter) || isSingleSelectable(type)) { // ? Add new filter or replace single selectable
    if (usesFilterTypeKey(type)) { // ? ex. 'tags'
      newFilter[type] = { [kkey]: value };
    } else { // ? ex. 'customerProxies'
      newFilter[type === 'benchmark' ? kkey : type] = isArray(value) && isSingleSelectable(type) ? value[0] : value; // ? if date, pick first index
    }
  } else { // ? Need to merge
    // eslint-disable-next-line no-lonely-if
    if (usesFilterTypeKey(type)) { // ? 'tags' need to merge
      if (!isEmpty(newFilter[type][kkey])) { // ? Need to merge kkey
        if (compareSetValues(newFilter[type][kkey], value)) { // ?if incoming value is longer than current we don't merge, we replace.
          newFilter[type] = { ...newFilter[type], [kkey]: value };
        } else {
          newFilter[type] = { ...newFilter[type], [kkey]: union(newFilter[type][kkey], value) };
        }
      } else { // ? the kkey doesn't need merging, but tags do (new tag added to tags).
        newFilter[type] = {
          ...newFilter[type],
          [kkey]: value,
        };
      }
    } else { // ? ex. 'customerProxies', Need to merge
      // eslint-disable-next-line no-lonely-if
      if (
        (newFilter[type]?.length || newFilter[kkey]?.length) > value.length && compareSetValues(newFilter[type], value)
      ) { // ? if incoming value is longer than current we don't merge, we replace.
        if (kkey in newFilter) newFilter[kkey] = value; // ? kkey if 'benchmark', type if filter
        else newFilter[type] = value;
      } else {
        const merged = Array.from(new Set([
          ...(newFilter[type] || []),
          ...value,
        ]));
        if (kkey in newFilter) newFilter[kkey] = merged; // ? kkey if 'benchmark', type if filter
        else newFilter[type] = merged;
      }
    }
  }
  return newFilter;
}

export const wasOpenedFromFilter = (slug) => {
  if (['segment', 'customerProxies', 'topics', 'answers', 'step'].includes(slug)) return slug;
  const tagsPrefix = 'tags__';
  const answersPrefix = 'answers__';
  if (slug?.substring(0, tagsPrefix.length) === tagsPrefix) return slug;
  if (slug?.substring(0, answersPrefix.length) === answersPrefix) return slug;
  return false;
};

export const wasOpenedFromBenchmark = (slug, addPrefix = false) => {
  const benchmarkPrefix = 'benchmark__';
  if (['sector', 'size', 'industry', 'location'].includes(slug)) return addPrefix ? benchmarkPrefix + slug : slug;
  if (slug?.substring(0, benchmarkPrefix.length) === benchmarkPrefix) return addPrefix ? benchmarkPrefix + slug : slug;
  return false;
};

// Returns a filter key without the negation (!) prefix
export const getPureFilterKey = (key) => (key.startsWith('!') ? key.slice(1) : key);
// Potentially adds negation prefix to a pure key based on operator
export const getPrefixedFilterKey = (pureKey, operator) => (operator === FILTER_OPERATOR_EXCLUDE ? `!${pureKey}` : pureKey);
export const getPrefixedFilterKeyIn = (pureKey, operator) => (operator === FILTER_OPERATOR_EXCLUDE ? `${pureKey}__not_in` : `${pureKey}__in`);
// Returns the operator for a filter key
export const getFilterOperator = (key) => (key.startsWith('!') ? FILTER_OPERATOR_EXCLUDE : FILTER_OPERATOR_INCLUDE);
