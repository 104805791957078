<template>
  <div
    v-tc-loader-pill="loading"
    class="tc-pane-settings-board tc-loader-pill-wrapper"
  >
    <div class="hgroup tc-pane-header">
      <button
        class="btn-reset tc-pane-close-btn"
        @click.prevent="closePane({
          areYouSure: queryDiffersFromSavedQuery
            ? $gettext('Du har osparade ändringar, är du säker på att du vill stänga sidpanelen innan du sparat?')
            : null
        })"
      >
        <i class="zmdi zmdi-close" />
      </button>
      <h3
        v-if="context.segment.id"
        class="tc-pane-title"
      >
        <i class="zmdi zmdi-edit pr-1" />
        {{ $gettext('Egenskaper') }}
      </h3>
      <h3
        v-else
        class="tc-pane-title"
      >
        <i class="zmdi zmdi-plus pr-1" />
        {{ $gettext('Nytt segment') }}
      </h3>
    </div>

    <form
      class="tc-pane-body"
      @submit.prevent
    >
      <div class="form-group">
        <label>{{ $gettext('Namn') }}</label>
        <input
          v-model="name"
          class="form-control"
          required
          :class="{ 'is-invalid': nameInvalid }"
          type="text"
        >
        <div
          v-if="nameInvalid"
          class="help-block validation-message is-invalid mt-0.5"
        >
          {{ $gettext('Segmentet behöver ett namn') }}
        </div>
      </div>
      <div
        v-if="context.segment.id"
        v-tooltip="{
          content: membersList.join(',\n'),
          placement: 'left',
          distance: 10,
          container: 'body',
          boundary: $parent.$parent.$el,
        }"
        class="form-group"
      >
        <small>
          {{ $npgettext(
            "Paragraph — X users",
            "%{ userAmount } medlem",
            "%{ userAmount } medlemmar",
            users.length,
            { userAmount: users.length }
          ) }}
        </small><br>
        <p
          v-if="users.length > 0"
          class="subtle-text small-text"
        >
          {{ membersList[0] }}
          {{ $npgettext(
            "Paragraph — And X users more",
            "är enda medlemmen i detta segment",
            "och %{ userAmount } till är medlemmar i detta segment",
            users.length,
            { userAmount: users.length - 1 }
          ) }}
        </p>
      </div>
      <div class="tc-pane-header tc-pane-bleed my-3">
        <h3 class="mb-1">
          <i class="zmdi zmdi-block pr-1" />
          <span>{{ $gettext('1. Välj avgränsning') }}</span>
        </h3>
        <h4 class="label mt-1">
          {{ $gettext('Vilken data ska segmentet ha tillgång till?') }}
        </h4>
      </div>
      <segment-query-list
        ref="segmentQueryList"
        v-model:query="query"
        class="tc-pane-bleed"
        @removed-key="removeFromQuery"
      />
      <new-tag-filter
        v-model:filter="newTagFilter"
        class="mb-2"
        :customer="true"
        :filter-out-keys="filterOutTagKeys"
        :filter-out-segment-tags="false"
        :enable-add-btn="true"
        @update:filter="tagFilterUpdate"
        @add-filter="addTagToQuery"
      />
      <new-level-filter
        v-if="showLevelFilter"
        ref="levelFilter"
        v-model:filter="newLevelFilter"
        class="mb-2"
        @update:filter="levelFilterUpdate"
        @add-filter="addLevelToQuery"
      />
      <new-proxy-filter
        v-if="showProxyFilter"
        ref="proxyFilter"
        v-model:filter="newProxyFilter"
        @update:filter="proxyFilterUpdate"
        @add-filter="addProxyToQuery"
      />

      <transition
        name="slide-fade"
        mode="out-in"
      >
        <p
          v-show="queryDiffersFromSavedQuery"
          class="alert alert-warning mt-2"
        >
          <i class="zmdi zmdi-alert-triangle tc-color-yellow" />
          <span class="strong">
            {{ $gettext('Du har osparade ändringar') }}
          </span>
          <button
            class="btn btn-text"
            :disabled="loading || saving || removing"
            @click.prevent="revert"
          >
            <span>{{ $gettext('Ångra ändringar') }}</span>
          </button>
        </p>
      </transition>
      <div class="tc-pane-header tc-pane-bleed mt-3 mb-2">
        <h3 class="mb-1">
          <i class="zmdi zmdi-filter-list pr-1" />
          <span>{{ $gettext('2. Välj filter') }}</span>
        </h3>
        <h4 class="label mt-1">
          {{ $gettext('Vilka filter ska finnas tillgängliga i segmentet?') }}
        </h4>
      </div>
      <div class="form-group">
        <label>{{ $gettext('Följande filter är tillgängliga i detta segment') }}</label>
        <tc-select
          v-model="tags"
          :multiselect="true"
          :on-change="filteredTagKeyOptions"
          :clean-paginator-fn="cleanTagsPaginatorFn"
          :populate-store-fn="populateTagsStoreFn"
          :loading-store-promise="fetchingTagKeys"
        />
      </div>

      <template v-if="!context.segment.id">
        <div class="tc-pane-header tc-pane-bleed mt-3 mb-2">
          <h3 class="mb-1">
            <i class="zmdi zmdi-chart pr-1" />
            <span>{{ $gettext('3. Kopiera rapporter') }}<span
              class="subtle-text ml-1"
              v-text="$gettext('(valfritt)')"
            /></span>
          </h3>
          <h4 class="label">
            {{ $gettext('Vill du kopiera rapporter från ett annat segment?') }}
          </h4>
        </div>
        <div class="form-group">
          <label>{{ $gettext('Segment att kopiera publika rapporter från') }}</label>
          <tc-select
            v-model="copyReportsFromSegment"
            :multiselect="false"
            :can-deselect="true"
            :populate-store-fn="populateSegmentStoreFn"
            :loading-store-promise="fetchingSegmentsPromise"
            :on-change="segmentsOptions"
            :clean-paginator-fn="cleanSegmentPaginatorFn"
          />
        </div>
      </template>

      <hr>

      <div
        v-tc-loader-spinner="saving"
        class="form-group buttons mt-4 tc-loader-spinner-wrapper"
      >
        <button
          v-if="context.segment.id"
          class="btn btn-text"
          :disabled="saving || removing"
          @click.prevent="remove"
        >
          <span>{{ $gettext('Ta bort') }}</span>
        </button>
        <button
          class="btn"
          type="submit"
          :disabled="saving || removing"
          @click.prevent="save"
        >
          <i class="zmdi zmdi-floppy" />
          <span>{{ saveText }}</span>
        </button>
      </div>

      <p class="alert alert-info mt-2">
        <i class="zmdi zmdi-info-outline" />
        <span class="">
          {{ $gettext('Ändringar kan ta 5–60 minuter att ge effekt') }}
        </span>
      </p>
    </form>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { klona } from 'klona';
import { isPlainObject } from 'lodash-es';
import segmentUserAPI from 'API/segmentUser';
import segmentAPI from 'API/segment';
import tagsAPI from 'API/tags';
import { vacuum, formatUserDisplayNames, striphtml } from 'Utils/general';
import { unprefixTag, prefixTag, suffixTag } from 'Utils/api';
import { handleErrorResponse } from 'Utils/views';
import {
  getPureFilterKey,
  getFilterOperator,
  getPrefixedFilterKey,
} from 'Utils/filterBox';
import { confirm } from 'Modal/dialogBoxes';
import NewTagFilter from 'Components/parts/filters/NewTagFilter';
import NewLevelFilter from 'Components/parts/filters/NewLevelFilter';
import NewProxyFilter from 'Components/parts/filters/NewProxyFilter';
import SegmentQueryList from 'Components/parts/segment/SegmentQueryList';
import { ErrCodeError } from '@/utils/errors';
import { closePane } from './Pane';

export default {
  name: 'PaneSettingsSegment',
  components: {
    NewTagFilter,
    NewLevelFilter,
    NewProxyFilter,
    SegmentQueryList,
  },
  props: {
    context: Object,
  },
  data() {
    return {
      name: '',
      nameInvalid: false, // should use vee-validate for these in next version
      id: null,
      tags: [],
      query: {}, // query that is temporarily shown until saved
      savedQuery: {}, // query that is gotten from DB
      copyReportsFromSegment: [],
      saving: false,
      loading: false,
      removing: false,
      addFilter: false,
      availableTags: [],
      newTagFilter: {},
      newLevelFilter: [],
      newProxyFilter: [],
      users: [],
    };
  },
  computed: {
    ...mapGetters([
      'customerId',
      'segmentId',
      'segmentsOptions',
      'fetchingSegmentsPromise',
      'customerTagKeyOptions',
      'fetchingTagKeys',
    ]),
    filteredTagKeyOptions() { return (term) => (this.customerTagKeyOptions(term)); },
    saveText() {
      return this.saving ? this.$gettext('Sparar') : this.$gettext('Spara');
    },
    showProxyFilter() {
      const proxyKey = 'customer_proxy_id__in';
      return !this.query[proxyKey] && !this.query[`!${proxyKey}`];
    },
    showLevelFilter() {
      const levelKey = 'level__in';
      return !this.query[levelKey] && !this.query[`!${levelKey}`];
    },
    membersList() {
      return formatUserDisplayNames(this.users);
    },
    filterOutTagKeys() {
      return Object.keys(this.query).map((q) => {
        const keys = q.split('__');
        return ['tags', '!tags'].includes(keys[0]) ? keys[1] : null;
      }).filter((q) => q !== null);
    },
    queryDiffersFromSavedQuery() {
      return JSON.stringify(this.query) !== JSON.stringify(this.savedQuery); // Is unfortunately index-sensitive
    },
  },
  watch: {
    context(context) {
      if (context) {
        this.load(context);
      }
    },
  },
  mounted() {
    this.$nextTick(() => {
      this.load(this.context);
    });
  },
  methods: {
    unprefixTag,
    closePane,
    ...mapActions([
      'notify',
      'setSegment',
      'setupSegment',
      'fetchSegments',
      'setSegmentsValues',
      'setLastSearchState',
      'setTagKeys',
      'fetchTagKeys',
      'fetchMe',
    ]),
    populateTagsStoreFn(keysPaginator) {
      return this.setTagKeys({ tagKeysPaginator: keysPaginator, customer: true }); // eslint-disable-line max-len
    },
    cleanTagsPaginatorFn(reqPag, populator) {
      const formatPopulator = (opt) => {
        if (opt?.label) return { key: opt.value, value: opt.label };
        return { key: opt.key, value: opt.value };
      };
      return ({
        ...reqPag._dataset,
        results: populator?.stack.map(formatPopulator) || [],
      });
    },
    cleanSegmentPaginatorFn(reqPag, populator) {
      return ({
        ...reqPag._dataset,
        results: populator?.stack.map((option) => option) || [],
      });
    },
    populateSegmentStoreFn(segmentsPaginator) {
      return this.setSegmentsValues({ segmentsPaginator, customerId: this.customerId }); // eslint-disable-line max-len
    },
    removeFromQuery(key) {
      delete this.query[key];
      this.newTagFilter = {};
      this.newLevelFilter = [];
      this.newProxyFilter = [];
    },
    addTagToQuery(newTagFilter = this.newTagFilter) {
      let tempQuery = { ...this.query };
      Object.keys(newTagFilter).forEach((key) => {
        const operator = getFilterOperator(key);
        tempQuery[getPrefixedFilterKey(suffixTag(prefixTag(getPureFilterKey(key))), operator)] = newTagFilter[key]; // ? Surround with `tags__${key}__in`
      });
      this.newTagFilter = {};
      this.query = tempQuery;
    },
    addLevelToQuery({ levels = this.newLevelFilter, operator }) {
      const levelKey = getPrefixedFilterKey('level__in', operator);
      this.query = {
        ...this.query,
        [levelKey]: levels,
      };
      this.newLevelFilter = [];
    },
    addProxyToQuery({ proxies = this.newProxyFilter, operator }) {
      const proxyKey = getPrefixedFilterKey('customer_proxy_id__in', operator);
      this.query = {
        ...this.query,
        [proxyKey]: Array.from(new Set([
          ...(this.query[proxyKey] ? this.query[proxyKey] : []),
          ...proxies,
        ])),
      };
      this.newProxyFilter = [];
    },
    revert(e) {
      if (this.$refs.segmentQueryList) this.$refs.segmentQueryList.disableEdit();
      this.newTagFilter = {};
      this.newLevelFilter = [];
      this.newProxyFilter = [];
      this.query = this.savedQuery;
    },
    tagFilterUpdate(addedTags) { this.newTagFilter = addedTags; },
    levelFilterUpdate(addedLevels) { this.newLevelFilter = addedLevels; },
    proxyFilterUpdate(addedProxies) { this.newProxyFilter = addedProxies; },
    getErrorText(err) {
      const strArr = [];
      if (err instanceof ErrCodeError) {
        const invalidTags = err.responseBody.tags.details?.tags || null;

        // ErrCodeError does not have properties like name, copy_reports_from_segment, etc.
        if (invalidTags) {
          const invalidTagsStr = invalidTags.map((t) => `"${t}"`).join(', ');

          strArr.push(
            this.$ngettext(
              'Filtret %{invalidTagsStr} är ogiltigt, vänligen ta bort det och försök igen',
              'Filter (%{invalidTagsStr}) är ogiltiga, vänligen ta bort dessa och försök igen',
              invalidTags.length,
              { invalidTagsStr },
              true,
            ),
          );
        }
      } else {
        if (err?.name) strArr.push(this.$pgettext('Error — invalid name', 'Segmentet måste ha ett giltigt namn'));
        if (err?.copy_reports_from_segment) strArr.push(this.$pgettext('Error — invalid copy_reports_from_segment', 'Segmentet du vill kopiera rapporter från finns inte'));
        if (err?.non_field_errors) {
          if (err.non_field_errors[0] === 'The fields customer, name must make a unique set.') strArr.push(this.$pgettext('Error — invalid non-unique name', 'Segmentet måste ha ett unikt namn'));
          else strArr.push(err.non_field_errors.join('. '));
        }
      }

      if (!strArr.length) {
        strArr.push(this.$pgettext('Error — general', 'Någonting gick fel, försök igen eller kontakta oss'));
      }

      return strArr.join('. ');
    },
    save(e) {
      let promise;
      let tempQuery = { ...this.query };
      this.nameInvalid = false;
      if (striphtml(this.name).length === 0) {
        this.nameInvalid = true;
        this.notify({
          text: this.$pgettext('Notification — Invalid segment save, missing name', 'Kunde inte spara segmentet, skriv in ett namn'),
          level: 'error',
          type: 'pop',
        });
        return Promise.resolve();
      }
      this.saving = true;
      let keys = Object.keys(this.newTagFilter);
      if (keys.length > 0) {
        const operator = getFilterOperator(keys[0]);
        tempQuery[getPrefixedFilterKey(suffixTag(prefixTag(getPureFilterKey(keys[0]))), operator)] = this.newTagFilter[keys[0]]; // ? Surround with `tags__${keys[0]}__in`
      }
      if (this.newLevelFilter.length > 0) {
        const levelKey = tempQuery.level__in ? 'level__in' : '!level__in';
        tempQuery = {
          ...tempQuery,
          [levelKey]: this.newLevelFilter,
        };
      }
      const proxyKey = tempQuery.customer_proxy_id__in ? 'customer_proxy_id__in' : '!customer_proxy_id__in';
      if (tempQuery[proxyKey] || this.newProxyFilter.length > 0) {
        tempQuery = {
          ...tempQuery,
          [proxyKey]: Array.from(
            new Set(this.newProxyFilter.concat(tempQuery[proxyKey] ? tempQuery[proxyKey] : [])),
          ),
        };
      }
      if (this.id === null) {
        promise = this.create({
          query: tempQuery,
          tags: this.tags.map((value) => value.value),
          ...((this.copyReportsFromSegment?.[0]?.value != null) && {
            copy_reports_from_segment: this.copyReportsFromSegment[0].value,
          }),
        }).then((segment) => {
          closePane();
          if (typeof this.context.onCreated === 'function') {
            this.context.onCreated(segment);
          }
          return segment;
        });
      } else {
        promise = this.update(this.id, {
          name: striphtml(this.name),
          query: tempQuery,
          tags: this.tags.map((value) => value.value),
        });
      }
      return promise
        .then((segment) => {
          if (typeof this.context.onSaved === 'function') {
            this.context.onSaved(segment);
          }
          this.setup(segment);
          this.notify({
            text: this.$pgettext('Notification — Saved segment', 'Segmentet har sparats! Ändringar kan ta 5–60 minuter att ge effekt'),
            level: 'success',
            type: 'pop',
          });
        }, (error) => handleErrorResponse(error, error.status === 400))
        .catch((error) => this.notify({
          text: this.$gettext('Kunde inte spara segment. %{error}', { error: this.getErrorText(error) }),
          level: 'error',
          type: 'pop',
        }))
        .then(() => {
          this.fetchMe(); // updates currentSegments in store
          this.saving = false;
        });
    },
    remove(e) {
      const title = this.$pgettext(
        'Confirm — remove segment warning text',
        'Är du säker på att du vill ta bort segmentet "%{segment}"? Alla medlemmar kommer kastas ut ur detta segment.',
        { segment: (this.context.segment.name || this.name) },
      );
      confirm({ title, confirmText: this.$gettext('Ja, ta bort segmentet'), dismissText: this.$gettext('Avbryt'), theme: 'danger' })
        .then(this.confirmRemove(this.id))
        .catch(this.removeError(this.id));
    },
    confirmRemove(id) {
      return (remove) => {
        if (remove) {
          closePane();
          return segmentAPI.remove(id).then(() => {
            this.setLastSearchState?.({});
            if (this.segmentId === id) {
              this.setupSegment(this.$router);
            }
            if (this.context && typeof this.context.onRemove === 'function') {
              this.context.onRemove(id);
            }
            this.fetchMe(); // updates currentSegments in store
          });
        }
        return Promise.resolve();
      };
    },
    removeError(id) {
      return (error) => this.notify({
        level: 'error',
        text: `${this.$pgettext('Error — Couldn’t remove segment', 'Kunde inte ta bort segmentet')} (${id}). ${error.text || error}`,
      });
    },
    create(props) {
      this.setLastSearchState?.({});
      return segmentAPI.create(this.customerId, this.name, props);
    },
    update(id, props) {
      return segmentAPI.patch(id, props).then((segment) => {
        this.setLastSearchState?.({});
        this.fetchMe(); // updates currentSegments in store
        if (segment.id === this.segmentId) {
          this.setSegment(segment);
        }
        return segment;
      });
    },
    getTags(customer) {
      return tagsAPI.list({ customer })
        .then((tags) => {
          this.availableTags = tags.map(tagsAPI.selectTag);
          return tags;
        }, (error) => this.notify({
          text: this.$gettext('Kunde inte hämta tags.'),
          level: 'warning',
        }));
    },
    getUsers(id) {
      segmentUserAPI.list({ segmentId: id }).then((users) => {
        this.users = users;
      });
    },
    setup(segment) {
      this.addFilter = false;
      this.users = [];
      this.newTagFilter = {};
      if (segment) {
        this.id = null;
        if (segment.id) {
          this.getUsers(segment.id);
          this.id = segment.id;
        }
        this.name = segment.name;
        this.query = { ...vacuum(segment.query, (val) => val.length > 0) };
        if (this.query.level) { // ? Migrates level: 'step' to level__in: ['step']
          this.query.level__in = [this.query.level];
          delete this.query.level;
        }
        this.savedQuery = klona(this.query);
        this.tags = isPlainObject(segment.tags)
          ? Object.entries(segment.tags).map(([key, value]) => ({
            value: key,
            label: value,
            icon: 'zmdi-label',
          }))
          : segment.tags.map((value) => ({
            value,
            label: value,
            icon: 'zmdi-label',
          }));
      }
      if (!this.loading && this.$refs.segmentQueryList) this.$refs.segmentQueryList.disableEdit();
      return segment;
    },
    load(context) {
      this.loading = true;
      this.fetchTagKeys(true); // ? Heavy load, necessary for tag filters here
      this.fetchSegments(this.customerId);
      this.getTags(this.customerId)
        // .then(this.getSegments(this.customerId))
        .then(() => {
          this.setup(context.segment);
          this.loading = false;
        });
    },
  },
};
</script>
