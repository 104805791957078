<template>
  <nav class="tabs-navigation tabs-navigation--columned">
    <ul class="tabs-navigation-items">
      <li
        v-for="tab of tabs"
        :key="tab.id"
        class="tabs-navigation-item"
        tabindex="0"
        :class="{ active: tab.value === modelValue }"
        @click="setTab(tab.value)"
        @keyup.enter="setTab(tab.value)"
      >
        <h3
          v-if="tab.title"
          class="tabs-navigation-title no-wrap"
        >
          {{ tab.title }}
        </h3>
        <h5
          v-if="tab.subtitle"
          class="tabs-navigation-subtitle no-wrap"
        >
          {{ tab.subtitle }}
        </h5>
      </li>
      <li class="tabs-navigation-selected" />
    </ul>
  </nav>
</template>

<script>
export default {
  name: 'TabsNavigation',
  props: {
    modelValue: String,
    tabs: {
      type: Array,
      required: true,
      validator(tabs) {
        // ? Must have between one and five tabs, all must be object with key 'value'
        return tabs.length > 1 && tabs.length <= 5 && tabs.every((tab) => tab.value);
      },
    },
  },
  emits: ['update:modelValue'],
  methods: {
    setTab(modelValue) {
      this.$emit('update:modelValue', modelValue);
    },
  },
};

// ? Use like
// <tabs-navigation
//   v-model="activeTab"
//   :tabs="taskTypes"
// />
// ? Where a tab is
// {
//   value: 'report',
//   title: 'Reports',
//   subtitle: 'That have been sent',
// },

</script>
