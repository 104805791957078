<template>
  <DropdownSelect
    :groups="groups"
    :dropdown-settings="props.dropdownSettings"
    @update:search-term="searchTerm = $event"
    @selected="handleSelected"
  />
</template>

<script setup>
import { ref, computed } from 'vue';
import { computedAsync } from '@vueuse/core';
import { BENCHMARK_ICONS } from 'Utils/benchmarks';
import { KEY_METRIC_ALLOWLIST_BENCHMARKABLES, KEY_METRIC_GRAPH_TYPES } from 'Utils/graph';
import DropdownSelect from 'Components/parts/filter-dropdowns/DropdownSelect';
import { store } from '@/store';
import gettext from '@/gettext';

const { $pgettext } = gettext;

const props = defineProps({
  includeGroup: {
    type: String,
    default: null,
  },
  excludeGroups: {
    type: Array,
    default: () => ([]),
  },
  badgeItem: {
    type: Object,
    default: () => ({}),
  },
  dropdownSettings: {
    type: Object,
    default: () => ({}),
  },
});

const emit = defineEmits(['selected']);

store.dispatch('fetchAllBenchmark', 'all');
const searchTerm = ref('');

const isLoadingSectors = computed(() => store.getters.customerIsFetching || store.getters.allBenchmarkIsFetching('sectors'));
const allBenchmarkSectorOptions = computedAsync(async () => {
  if (isLoadingSectors.value) return [];
  const isSectorBadge = props.badgeItem?.boxRowKey === 'sector';
  const options = await store.getters.allBenchmarkSectorOptions(searchTerm.value) || [];
  return options.map((opt) => ({ ...opt, icon: BENCHMARK_ICONS.sector, selected: (isSectorBadge && props.badgeItem?.boxRowValue?.includes(opt.value)) })); // eslint-disable-line max-len
}, []);

const isLoadingSizes = computed(() => store.getters.customerIsFetching || store.getters.allBenchmarkIsFetching('sizes'));
const allBenchmarkSizeOptions = computedAsync(async () => {
  if (isLoadingSizes.value) return [];
  const isSizeBadge = props.badgeItem?.boxRowKey === 'size';
  const options = await store.getters.allBenchmarkSizeOptions(searchTerm.value) || [];
  return options.map((opt) => ({ ...opt, icon: BENCHMARK_ICONS.size, selected: (isSizeBadge && props.badgeItem?.boxRowValue?.includes(opt.value)) })); // eslint-disable-line max-len
}, []);

const isLoadingIndustries = computed(() => store.getters.customerIsFetching || store.getters.allBenchmarkIsFetching('industries'));
const allBenchmarkIndustryOptions = computedAsync(async () => {
  if (isLoadingIndustries.value) return [];
  const isIndustryBadge = props.badgeItem?.boxRowKey === 'industry';
  const options = await store.getters.allBenchmarkIndustryOptions(searchTerm.value) || [];
  return options.map((opt) => ({ ...opt, icon: BENCHMARK_ICONS.industry, selected: (isIndustryBadge && props.badgeItem?.boxRowValue?.includes(opt.value)) })); // eslint-disable-line max-len
}, []);

const isLoadingLocations = computed(() => store.getters.customerIsFetching || store.getters.allBenchmarkIsFetching('locations') || store.getters.customStringTranslations._countries === undefined);
const allBenchmarkLocationOptions = computedAsync(async () => {
  if (isLoadingLocations.value) return [];
  const isLocationBadge = props.badgeItem?.boxRowKey === 'location';
  const options = await store.getters.allBenchmarkLocationOptions(searchTerm.value) || [];
  return options.map((opt) => ({ ...opt, icon: BENCHMARK_ICONS.location, selected: (isLocationBadge && props.badgeItem?.boxRowValue?.includes(opt.value)) })); // eslint-disable-line max-len
}, []);

const modalCard = computed(() => store.getters.modalCard);
const isModalCard = computed(() => modalCard.value?.metadata?.graphType !== undefined);
const isKeyMetricCard = computed(() => isModalCard.value
    && Object.values(KEY_METRIC_GRAPH_TYPES).includes(modalCard.value?.metadata?.graphType?.selected));

const groups = computed(() => {
  const allGroups = [
    ...(isModalCard.value ? [{
      groupSlug: 'general',
      groupTitle: '',
      loading: false,
      options: [
        {
          value: 'global',
          label: $pgettext('Label — Benchmark global', 'Global'),
          icon: 'zmdi-globe',
          multiselect: false,
          selected: props.badgeItem?.boxRowValue?.includes('global'),
        },
        ...(isKeyMetricCard.value && KEY_METRIC_ALLOWLIST_BENCHMARKABLES?.[modalCard.value?.metadata?.graphType?.selected]?.includes('company') ? [{
          value: 'company',
          label: store.getters.customerName || $pgettext('Option - KM FilterBox Benchmarks', 'Mitt företag'),
          icon: 'zmdi-city',
          multiselect: false,
          selected: props.badgeItem?.boxRowValue?.includes('company'),
        }] : []),
      ],
    }] : []),
    {
      groupSlug: 'sector',
      groupTitle: $pgettext('Title — Benchmark sector', 'Sektor'),
      loading: isLoadingSectors.value,
      options: allBenchmarkSectorOptions.value || [],
    },
    {
      groupSlug: 'size',
      groupTitle: $pgettext('Title — Benchmark size', 'Storlekar'),
      loading: isLoadingSizes.value,
      options: allBenchmarkSizeOptions.value || [],
    },
    {
      groupSlug: 'industry',
      groupTitle: $pgettext('Title — Benchmark industry', 'Branscher'),
      loading: isLoadingIndustries.value,
      options: allBenchmarkIndustryOptions.value || [],
    },
    {
      groupSlug: 'location',
      groupTitle: $pgettext('Title — Benchmark location', 'Platser'),
      loading: isLoadingLocations.value,
      options: allBenchmarkLocationOptions.value || [],
    },
  ];

  if (props.includeGroup) return allGroups.filter((group) => group.groupSlug === props.includeGroup);
  if (props.excludeGroups) return allGroups.filter((group) => props.excludeGroups.includes(group.groupSlug) === false);
  return allGroups;
});

const handleSelected = ({ options }) => emit('selected', options);
</script>
