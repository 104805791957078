<template>
  <li class="badge-grid">
    <helper-text-ellipsis
      :class="{
        'badge-overlapping': item.isCardFilter,
        'badge-error': !badgeNewAndEmpty && !isGettingTranslation && !canShowBadge || error,
        'badge-null': badgeNewAndEmpty,
      }"
      class="badge badge-filter"
      :tooltip="{
        content: badgeValuesTooltip,
        html: true,
        distance: 12,
      }"
      :override-ellipsis-show="tooManyValues || forceTooltips || isCNPS"
    >
      <span v-html="badgeText" />
      <i-material-symbols-translate
        v-if="error"
        aria-label="Error"
        class="ml-0.5 valign-bottom tc-color-red"
      />
      <div
        v-if="removable"
        v-tooltip="{
          content: () => $pgettext('Tooltip — Remove badge', 'Ta bort'),
          distance: 8,
          container: 'body',
          placement: 'right',
        }"
        class="remove"
        :aria-label="$pgettext('Tooltip — Remove badge', 'Ta bort')"
        @click.prevent="$emit('remove', item)"
      >
        <i class="zmdi zmdi-close" />
      </div>
    </helper-text-ellipsis>
  </li>
</template>

<script>
import { isArray, isEmpty, isObject } from 'lodash-es';
import { mapGetters, mapActions, mapState } from 'vuex';
import { hideAllPoppers } from 'floating-vue';
import { striphtml, translateTerm, translateBaseValue, processText, equalLiteral } from 'Utils/general';
import { NPS_NAMES } from 'Utils/question';
import { getPrefixedFilterKey, FILTER_OPERATOR_EXCLUDE } from 'Utils/filterBox';
import HelperTextEllipsis from 'Components/parts/helpers/HelperTextEllipsis';

const DETRACTOR_AMOUNT_MAX = 7;
const PASSIVE_AMOUNT_MAX = 2;
const PROMOTER_AMOUNT_MAX = 2;

export default {
  name: 'BadgeListItemFilter',
  components: {
    HelperTextEllipsis,
  },
  inject: {
    floatingVue: {
      from: '__floating-vue__popper',
      default: '',
    },
  },
  props: {
    item: {
      type: Object,
      required: true,
      default: () => ({}),
      validator: (item) => isArray(item.value) || item.value.endsWith('...'),
    },
    forceTooltips: {
      type: Boolean,
      default: false,
    },
    removable: {
      type: Boolean,
      default: false,
    },
    shouldOpenBadges: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['remove', 'update:shown'],
  data() {
    return {
      translatedItem: {},
      error: null,
    };
  },

  computed: {
    ...mapGetters(['segmentTagKeyVal', 'fetchingProxies']),
    ...mapState({ fetchingTagKeyList: (state) => state.tags.fetchingTagKeyList }),
    floatingVueState() {
      return this.floatingVue?.parentPopper ?? null;
    },
    isGettingTranslation() {
      if (this.item.slug?.startsWith('answers__')) return (this.item?.needsTranslation && isEmpty(this.translatedItem)) || false;
      if (this.item.slug?.includes('tags')) return this.fetchingTagKeyList.has(this.item.label);
      if (this.item.slug?.includes('customerProxies')) return this.fetchingProxies;
      return false;
    },
    valueLength() { return this.isCNPS ? Object.keys(this.npsCategories).length : this.item.value?.length; },
    canShowBadge() { return isArray(this.item.value) && this.valueLength > 0; },
    badgeNewAndEmpty() {
      if (!this.floatingVueState?.isShown) return false;
      const label = this.item.operator === FILTER_OPERATOR_EXCLUDE
        ? getPrefixedFilterKey(this.item.label, FILTER_OPERATOR_EXCLUDE)
        : this.item.label;
      return isArray(this.item?.boxRowValue) && this.item.boxRowValue.length === 0
      || isObject(this.item?.boxRowValue) && this.item?.boxRowValue?.[label]?.length === 0;
    },
    tooManyValues() { return this.valueLength > 2 || false; },
    badgeValuesTooltip() {
      let item = this.item;
      if (this.error) return striphtml(this.error);
      if (this.item.needsTranslation) {
        item = this.translatedItem;
        if (isEmpty(item)) return this.$gettext('Laddar...');
      }
      if (this.isGettingTranslation) return this.$gettext('Laddar...');
      if (!this.canShowBadge) return null;
      let labels = item.value?.filter((itm, idx) => idx < 11).join(', ');
      let label = item.label;
      if (item.overwriteTooltip) labels = item.overwriteTooltip;
      if (item.slug.includes('tags')) label = this.segmentTagKeyVal?.[label];
      return `<strong>${item.isCardFilter ? 'Card ' : ''}Filter – ${label}</strong>: ${labels}${this.valueLength >= 11 ? '…' : ''}`;
    },
    isCNPS() {
      if (this.item.needsTranslation && !isEmpty(this.translatedItem)) return this.translatedItem?.cnps || false;
      return this.item?.cnps;
    },
    npsCategories() {
      if (!this.isCNPS) return [];
      const values = this.item.value.reduce((acc, val) => {
        switch (true) {
          case val === 'detractor':
            acc = { ...acc, detractor: DETRACTOR_AMOUNT_MAX }; return acc;
          case val === 'passive':
            acc = { ...acc, passive: PASSIVE_AMOUNT_MAX }; return acc;
          case val === 'promoter':
            acc = { ...acc, promoter: PROMOTER_AMOUNT_MAX }; return acc;
          case Number(val) >= 0 && Number(val) <= 6:
            acc = { ...acc, detractor: acc.detractor + 1 }; return acc;
          case Number(val) === 7 || Number(val) === 8:
            acc = { ...acc, passive: acc.passive + 1 }; return acc;
          case Number(val) === 9 || Number(val) === 10:
            acc = { ...acc, promoter: acc.promoter + 1 }; return acc;
          default:
            return acc;
        }
      }, { detractor: 0, passive: 0, promoter: 0 });

      /* eslint-disable max-len */
      return Object.values(values).reduce((acc, catAmount, i) => {
        if (i === 0 && catAmount === DETRACTOR_AMOUNT_MAX) acc.push(NPS_NAMES().detractor);
        else if (i === 0 && catAmount > 0 && catAmount < DETRACTOR_AMOUNT_MAX) acc.push(this.item.value.filter((val) => ['0', '1', '2', '3', '4', '5', '6'].includes(val)).sort((a, b) => a - b).join(', '));

        if (i === 1 && catAmount === PASSIVE_AMOUNT_MAX) acc.push(NPS_NAMES().passive);
        else if (i === 1 && catAmount > 0 && catAmount < PASSIVE_AMOUNT_MAX) acc.push(this.item.value.filter((val) => ['7', '8'].includes(val)).sort((a, b) => a - b).join(', '));

        if (i === 2 && catAmount === 2) acc.push(NPS_NAMES().promoter);
        else if (i === 2 && catAmount > 0 && catAmount < 2) acc.push(this.item.value.filter((val) => ['9', '10'].includes(val)).sort((a, b) => a - b).join(', '));

        return acc;
      }, []).sort((a, b) => {
        if (a === NPS_NAMES().detractor || a === NPS_NAMES().passive || a === NPS_NAMES().promoter) return -1;
        if (b === NPS_NAMES().detractor || b === NPS_NAMES().passive || b === NPS_NAMES().promoter) return 1;
        return 0;
      });
      /* eslint-enable max-len */
    },
    badgeText() {
      const item = this.item.needsTranslation ? this.translatedItem : this.item;

      let len = this.valueLength;
      if (this.item.needsTranslation && isEmpty(item)) {
        if (this.error) return striphtml(this.error);
        return this.$gettext('Laddar...');
      }

      const label = this.getLabel(item);

      if (this.error) {
        return item.icon
          ? this.$pgettext('Loading — Badgelist item', '<i class="zmdi %{icon}"></i>', { icon: item.icon })
          : this.$pgettext('Loading — Badgelist item', '<strong>%{label}</strong>', { label });
      }
      if (this.badgeNewAndEmpty) return this.getEmptyBadgeText(item, label);
      if (this.isGettingTranslation) return this.getLoadingBadgeText(item, label);
      if (!this.canShowBadge) return this.getEmptyValueBadgeText(item, label);

      const level = item?.level && translateTerm(item.level) || '';

      let translationSmall = item.slug.startsWith('answers__')
        ? this.$npgettext(
          'Badgelist answers item interpolation small',
          '%{negation} <strong>%{val1}</strong> på frågan <strong>“%{label}”</strong>',
          '%{negation} <strong>%{val1}</strong> %{operand} <strong>%{val2}</strong> på frågan <strong>“%{label}”</strong>',
          len,
        )
        : this.$npgettext(
          'Badgelist item interpolation small',
          '<strong>%{label}</strong> %{is} <strong>%{val1}</strong>',
          '<strong>%{label}</strong> %{is} <strong>%{val1}</strong> %{operand} <strong>%{val2}</strong>',
          len,
        );
      let translationLarge = item.slug.startsWith('answers__')
        ? this.$pgettext(
          'Badgelist answers item interpolation large',
          '%{negation} <strong>%{val1}</strong> %{operand} <strong>%{len} andra</strong> på frågan <strong>“%{label}”</strong>',
        )
        : this.$pgettext(
          'Badgelist item interpolation large',
          '<strong>%{label}</strong> %{is} <strong>%{val1}</strong> %{operand} <strong>%{len} till</strong>',
        );

      let val1 = striphtml(item.value[0]) || '';
      let val2 = striphtml(item.value[1]) || '';

      if (this.isCNPS) {
        if (this.npsCategories.length >= 1) {
          val1 = this.npsCategories[0] || '';
          val2 = this.npsCategories[1] || '';
          len = this.npsCategories.length;
          if (val1 === NPS_NAMES().detractor) {
            len = item.value.length - DETRACTOR_AMOUNT_MAX + 1;
          } else if (val1 === NPS_NAMES().passive) {
            len = item.value.length - PASSIVE_AMOUNT_MAX + 1;
          } else if (val1 === NPS_NAMES().promoter) {
            len = item.value.length - PROMOTER_AMOUNT_MAX + 1;
          }
        }
        ({ translationSmall, translationLarge } = this.getCNPSTranslations(level, len));
      }
      return this.$gettext(
        this.tooManyValues ? translationLarge : translationSmall,
        {
          label,
          is: this.whiteList(len),
          negation: this.item.operator === FILTER_OPERATOR_EXCLUDE ? this.$pgettext('Badgelist answers item interpolation negation', 'Svarade inte') : this.$pgettext('Badgelist answers item interpolation', 'Svarade'),
          operand: item.operand || this.$pgettext('Badgelist item filter operand', 'eller'),
          val1,
          val2,
          len: len - 1,
          level,
        },
      );
    },
  },
  watch: {
    item: {
      handler(newVal, oldVal) {
        if (newVal?.needsTranslation && !equalLiteral(newVal, oldVal)) this.setTranslatedItem(newVal);
      },
      deep: true,
      immediate: true,
    },
  },
  mounted() {
    if (this.shouldOpenBadges) {
      hideAllPoppers();
      this.$emit('update:shown', { item: this.item, shown: true });
    }
  },
  methods: {
    ...mapActions(['segmentTagKeyTranslation', 'getQuestionById']),
    whiteList(len) {
      if (this.isCNPS) {
        const npsCategoriesIncludesNPS = this.npsCategories.includes(NPS_NAMES().detractor)
        || this.npsCategories.includes(NPS_NAMES().passive)
        || this.npsCategories.includes(NPS_NAMES().promoter);
        const length = npsCategoriesIncludesNPS ? 2 : 1;
        return this.item.operator === FILTER_OPERATOR_EXCLUDE
          ? this.$npgettext(
            'Badgelist item is not CNPS',
            'är inte',
            'är inte',
            length,
          )
          : this.$npgettext(
            'Badgelist item is CNPS',
            'är',
            'är',
            length,
          );
      }
      return this.item.operator === FILTER_OPERATOR_EXCLUDE ? this.$pgettext('Badgelist item is not', 'är inte', len) : this.$pgettext('Badgelist item is', 'är', len);
    },
    getLabel(item) {
      let label = striphtml(item.label);
      if (item.slug.includes('tags') && !this.error) {
        if (!this.segmentTagKeyVal?.[item.label] && !this.isGettingTranslation && !this.error) {
          // eslint-disable-next-line vue/no-async-in-computed-properties
          this.segmentTagKeyTranslation(item.label).catch((err) => { this.error = err; });
        }
        label = this.segmentTagKeyVal?.[item.label] || item.label;
      }
      return label;
    },
    getEmptyBadgeText(item, label, len) {
      return item?.icon
        ? this.$pgettext('Empty — Badgelist item', '<i class="zmdi %{icon}"></i> %{operand}', { icon: item.icon, operand: this.whiteList(len) })
        : this.$pgettext('Empty — Badgelist item', '<strong>%{label}</strong> %{operand}', { label, operand: this.whiteList(len) });
    },
    getLoadingBadgeText(item, label) {
      return item.icon
        ? this.$pgettext('Loading — Badgelist item', '<i class="zmdi %{icon}"></i> Laddar...', { icon: item.icon })
        : this.$pgettext('Loading — Badgelist item', '<strong>%{label}</strong> Laddar...', { label });
    },
    getEmptyValueBadgeText(item, label) {
      return item.icon
        ? this.$pgettext('Empty — Badgelist item', '<i class="zmdi %{icon}"></i> Saknar värde', { icon: item.icon })
        : this.$pgettext('Empty — Badgelist item', '<strong>%{label}</strong> Saknar värde', { label });
    },
    getCNPSTranslations(level, len) {
      let translationSmall;
      let translationLarge;

      if (level) {
        translationSmall = this.$npgettext(
          'Badgelist cnps-answers level item interpolation small',
          '%{is} <strong>%{val1}</strong> på <strong>CNPS-frågan för %{level}</strong>',
          '%{is} <strong>%{val1}</strong> %{operand} <strong>%{val2}</strong> på <strong>CNPS-frågan för %{level}</strong>',
          len,
        );
        translationLarge = this.$pgettext(
          'Badgelist cnps-answers level item interpolation large',
          '%{is} <strong>%{val1}</strong> %{operand} <strong>%{len} andra</strong> på <strong>CNPS-frågan för %{level}</strong>',
        );
      } else {
        translationSmall = this.$npgettext(
          'Badgelist cnps-answers item interpolation small',
          '%{is} <strong>%{val1}</strong> på <strong>“%{label}”</strong>',
          '%{is} <strong>%{val1}</strong> %{operand} <strong>%{val2}</strong> på <strong>“%{label}”</strong>',
          len,
        );
        translationLarge = this.$pgettext(
          'Badgelist cnps-answers item interpolation large',
          '%{is} <strong>%{val1}</strong> %{operand} <strong>%{len} andra</strong> på <strong>“%{label}”</strong>',
        );
      }
      return { translationSmall, translationLarge };
    },
    async setTranslatedItem(item = {}) {
      if (item.slug.startsWith('answers__')) {
        try {
          const ques = await this.getQuestionById(item.label);
          this.translatedItem = {
            ...item,
            ...(ques?.level && { level: ques.level }),
            ...(ques?.options?.cnps && { cnps: ques.options.cnps }),
            label: processText(ques?.translation?.question) ?? item.key,
            value: item.value.map((ans) => translateBaseValue(ans, ques) || ans),
          };
        } catch (err) {
          this.translatedItem = item;
        }
      }
    },
  },
};
</script>
