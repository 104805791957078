import gaPush from '@/plugins/googleAnalytics';
import { identify as identifySentry } from '@/plugins/sentry';
import murmurhash from 'Utils/murmurhash';
import { getBaseUrl, putUrl, patchUrl } from 'Utils/api';
import { handleAuthError } from 'Utils/authHelpers';
import { store } from '@/store';
import gettext from '@/gettext';

const { $gettext } = gettext;

// const SLACK_URL = 'https://hooks.slack.com/services/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';

function me() {
  return getBaseUrl('/feedback/accounts/api/user/me/')
    .then((response) => response.body, handleAuthError)
    .then((user) => {
      identifySentry(user);
      gaPush({
        event: '[TC] ME',
        user_id: murmurhash(`${user.id}`, 31),
        is_staff: user?.is_staff,
        ...(process.env.NODE_ENV !== 'production' && { debug_mode: true }),
      });
      return user;
    });
}

function update(id, details) {
  return putUrl(`/feedback/accounts/api/user/${id}/`, details).catch((err) => Promise.reject(err));
}

export const validVisualPreferences = { // use with typeof. Ex. visual_preferences: { surveyPageView:'card', segmentSelectWidth:537 }
  surveyPageView: 'string',
  segmentSelectWidth: 'number',
};
export const validNotificationTypes = ['flagged_respondent']; // Ex. notification_preferences: { flagged_respondent:{ single_send: false, summary_send: false } }
export const validNotificationPreferences = {
  single_send: 'boolean',
  summary_send: 'boolean',
};

const validatePreference = (key, value, validPrefs) => {
  if (!validPrefs?.[key]) throw new Error(`Invalid preferences key: "${key}"`);
  if (typeof value !== validPrefs[key]) throw new Error(`Invalid preferences value type: "${value}" isn't a ${validPrefs[key]}`); // eslint-disable-line valid-typeof
  return true;
};

function notifyError(err) {
  store.dispatch('notify', {
    type: 'slow',
    level: 'warning',
    text: $gettext(
      'Kunde inte spara inställningen. %{msg}',
      { msg: err.message },
    ),
  });
}

/**
 ** patchVisualPreferences - Set visual_preferences key-value pairs. Use with a store upate (setVisualPreferences).
 * @param { [keyof validVisualPreferences]: value } keyValues - { key: value }
 * @returns Promise
 */
function patchVisualPreferences(keyValues = {}, patchStoreBeforeResponse = true) {
  try {
    const currPreferences = store.getters?.me?.visual_preferences || {};
    const samePrefsAsBefore = Object.entries(keyValues).every(([key, value]) => currPreferences[key] === value);
    if (samePrefsAsBefore) return Promise.resolve();

    const validatedPrefs = Object.entries(keyValues).reduce((acc, [key, value]) => {
      validatePreference(key, value, validVisualPreferences);
      acc[key] = value;
      return acc;
    }, {});
    const newPreferences = { ...currPreferences, ...validatedPrefs };
    if (patchStoreBeforeResponse) {
      const newMe = { ...store.getters?.me, visual_preferences: newPreferences };
      store.commit('SET_ME', newMe);
    }
    return patchUrl('/feedback/accounts/api/user/me/', { visual_preferences: newPreferences }); // Must return updated me object
  } catch (err) {
    notifyError(err);
    return Promise.reject(err);
  }
}

/**
 ** patchNotificationPreferences - Set notification_preferences key-value pairs for any notification type. Use with a store upate (setNotificationPreferences).
 * @param {string} prefType - Type of notification preference (e.g. 'flagged_respondent')
 * @param { [key: string]: any } keyValues - Preference key-value pairs
 * @returns Promise
 */
function patchNotificationPreferences(prefType, keyValues = {}, patchStoreBeforeResponse = true) {
  try {
    if (!validNotificationTypes.includes(prefType)) throw new Error(`Invalid notification type: "${prefType}"`);
    const currTypePreferences = store.getters?.me?.notification_preferences?.[prefType] || {};
    const samePrefsAsBefore = Object.entries(keyValues).every(([key, value]) => currTypePreferences[key] === value);
    if (samePrefsAsBefore) return Promise.resolve();

    const validatedPrefs = Object.entries(keyValues).reduce((acc, [key, value]) => {
      validatePreference(key, value, validNotificationPreferences);
      acc[key] = value;
      return acc;
    }, {});
    const newPreferences = {
      ...store.getters?.me?.notification_preferences,
      [prefType]: {
        ...currTypePreferences,
        ...validatedPrefs,
      },
    };
    if (patchStoreBeforeResponse) {
      const newMe = { ...store.getters?.me, notification_preferences: newPreferences };
      store.commit('SET_ME', newMe);
    }
    return patchUrl('/feedback/accounts/api/user/me/', { notification_preferences: newPreferences });
  } catch (err) {
    notifyError(err);
    return Promise.reject(err);
  }
}

export default {
  me,
  update,
  patchVisualPreferences,
  patchNotificationPreferences,
  validatePreference,
};
