<template>
  <div
    class="stroked-area"
    :class="{ active, [theme]: theme }"
  >
    <transition name="slide-in">
      <div
        v-if="active && (title || button)"
        class="stroked-area-top"
      >
        <h3
          v-if="title"
          class="stroked-area-title"
          v-text="title"
        />
        <div
          v-if="button?.length > 0"
          class="stroked-area-buttons"
        >
          <button
            v-for="(btn, idx) in button"
            :key="btn.text"
            v-tooltip="btn?.tooltip || null"
            class="stroked-area-button btn btn-ghost-dark btn-thin"
            :class="{ 'stroked-area-button--secondary': idx > 0 }"
            @click="btn.action"
          >
            {{ btn.text }}
          </button>
        </div>
        <button
          v-else-if="button"
          v-tooltip="button?.tooltip || null"
          class="stroked-area-button btn btn-ghost-dark btn-thin"
          @click="button.action"
        >
          {{ button.text }}
        </button>
      </div>
    </transition>
    <slot />
  </div>
</template>

<script setup>
const props = defineProps({
  active: {
    type: Boolean,
    default: false,
  },
  theme: {
    type: String,
    default: '', // 'success' || 'warning' || 'danger' || 'info' || 'brand'
  },
  title: {
    type: String,
    default: '',
  },
  button: {
    type: [Object, Array, null],
    default: null,
    // eslint-disable-next-line eqeqeq
    validator: (btn) => !btn == undefined // null ok
      || (btn.text !== undefined && btn.action !== undefined) // { text, action } ok
      || (btn?.length > 0 && btn.every((item) => item.text !== undefined && item.action !== undefined)), // { text, action }[] ok
  },
});
</script>
