// ! `event` is the most important part of the GA push, make sure its name is future proof, check CONTRIBUTING.md for more info
function googleAnalytics(args = {}, event = '[TC] Event') {
  if (!['production', 'staging'].includes(process.env.NODE_ENV)) {
    /* eslint no-console: ["error", { allow: ["log", "warn", "error"] }] */
    console.log('[TC] GA Send', { event, ...args });
  } else if (window.dataLayer) {
    window.dataLayer.push({ event, ...args });
  }
}

export default googleAnalytics; // eg. gaPush
